import React, { useEffect } from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import { getModules } from '../../../redux/actions/indexActions'
import axios from "axios";
import { toast } from "react-toastify";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
    FormGroup,
    Input
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";


const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const [data, setData] = React.useState([]);
    const [roleState, setroleState] = React.useState(null);
    const [role, setRole] = React.useState("");
    const [modalAdd, setModalAdd] = React.useState(false);
    const dispatch = useDispatch()
    const { indexReducer: { modules, loading }, AuthReducer: { auth } } = useSelector(state => state)
    useEffect(() => {
        dispatch(getModules(auth?.token))
    }, [auth?.token])
    useEffect(() => {
        setData(modules);
    }, [modules])
    const handleDel = (id, name) => {
        if (window.confirm("Are You Sure You Want To Delete This Category")) {
            axios.delete(`/api/admin/v1/module/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`
                }
            })
                .then((res) => {
                    toast.success(res.data.message)
                    dispatch(getModules(auth?.token))
                }
                )
        }
    }
    const handleModal = (e) => {
        e.preventDefault();
        setModalAdd(!modalAdd)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (roleState === "") {
            setroleState("invalid");
        } else {
            setroleState("valid");
        }
        if (role) {
            const name = role;
            axios.post("/api/admin/v1/module-create", { name }, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
                .then((res) => {
                    setModalAdd(false)
                    toast.success("Module Created Successfully")
                    setRole("")
                    dispatch(getModules(auth?.token))

                }).catch((err) => {
                    toast.error(err.response.data.message)
                })
        }

    };
    return (
        <>
            <SimpleHeader name="Role Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Module</h3></Col>
                                    <Col xs="4" className="text-right">
                                        <Button className="btn-neutral" color="default" size="sm" onClick={handleModal}>
                                            New Module
                                        </Button>
                                    </Col>
                                </Row>

                            </CardHeader>
                            <ToolkitProvider
                                data={data}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "Module Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {
                                            return (
                                                <Button color="danger" outline type="button" onClick={() => handleDel(row.id, row.name)}>
                                                    Delete
                                                </Button>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>

                <Modal
                    isOpen={modalAdd}
                    toggle={() => setModalAdd(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                    <form className="new-event--form">
                        <div className="modal-body">

                            <FormGroup>
                                <label className="form-control-label">Module name</label>
                                <Input
                                    className="form-control-alternative new-event--title"
                                    id="validationCustom01"
                                    placeholder="Role Name"
                                    type="text"
                                    valid={roleState === "valid"}
                                    invalid={roleState === "invalid"}
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                        if (e.target.value === "") {
                                            setroleState("invalid");
                                        } else {
                                            setroleState("valid");
                                        }
                                    }}
                                />
                                <div className={`${roleState === "valid" ? 'valid-feedback' : 'invalid-feedback'}`}>{roleState === "valid" ? "Good!" : "Please enter role name."}</div>

                            </FormGroup>

                        </div>
                        <div className="modal-footer">
                            <Button
                                type="submit"
                                className="new-event--add sm"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Add
                            </Button>
                            <Button
                                className="ml-auto"
                                color="link"
                                type="button"
                                onClick={() => setModalAdd(false)}
                            >
                                Close
                            </Button>
                        </div>
                    </form>

                </Modal>
            </Container>
        </>
    );
}

export default Index;
