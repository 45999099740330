import React, { useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { updateCoupon, getCouponByIDs } from "../../../redux/actions/CouponActions";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import {
    useParams,
} from "react-router-dom";
const Edit = () => {
    const dispatch = useDispatch();
    const { couponReducer: { errors, updateStatus, coupon }, AuthReducer: { auth } } = useSelector(state => state);
    const [state, setState] = React.useState({
        coupon_code: "",
        coupon_type: "percentage",
        start_date: "",
        end_date: "",
        amount: "",
        min_deposit_amount : "",
        max_offer_amount : "",
        status: 1,
    })
    const { id } = useParams();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }
    useEffect(() => {
        if (coupon) {
            setState({
                ...state,
                coupon_code: coupon?.coupon_code,
                coupon_type: coupon?.coupon_type,
                start_date: coupon?.start_date,
                end_date: coupon?.end_date,
                amount: coupon?.amount,
                min_deposit_amount : coupon?.min_deposit_amount,
                max_offer_amount : coupon?.max_offer_amount,
                status: 1,
            })
        }
    }, [coupon])
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateCoupon(id, auth?.token, state))
    }
    React.useEffect(() => {
        dispatch(getCouponByIDs(auth?.token, id))
    }, [auth])
    React.useEffect(() => {
        if (updateStatus) {
            toast.success("Updated Successfully!!")
        }
    }, [updateStatus])

    return (
        <>
            <SimpleHeader name="Insert Contest" parentName="Contest Lists" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Create Contest</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="coupon_code"
                                                >
                                                    Coupon Code
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="coupon_code"
                                                    name="coupon_code"
                                                    invalid={Boolean(errors?.coupon_code)}
                                                    onChange={handleChange}
                                                    value={state?.coupon_code}
                                                />


                                                <div className="invalid-feedback">{errors?.coupon_code}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="coupon_type"
                                                >
                                                    Coupon Type
                                                </label>
                                                <Input
                                                    id="coupon_type"
                                                    name="coupon_type"
                                                    placeholder="Coupon Type"
                                                    type="select"
                                                    onChange={handleChange}
                                                    value={state?.coupon_type}
                                                    invalid={Boolean(errors?.coupon_type)}

                                                >
                                                    <option value={"percentage"}>Percentage</option>
                                                    <option value={"flat"}>Flat</option>
                                                </Input>
                                                <div className="invalid-feedback">{errors?.coupon_type}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="start_date"
                                                >
                                                    Start Date
                                                </label>
                                                <Input
                                                    id="start_date"
                                                    name="start_date"
                                                    placeholder="start_date"
                                                    type="date"
                                                    onChange={handleChange}
                                                    value={state?.start_date}
                                                    invalid={Boolean(errors?.start_date)}

                                                />
                                                <div className="invalid-feedback">{errors?.start_date}</div>
                                            </Col>

                                        </div>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="end_date"
                                                >
                                                    End Date
                                                </label>
                                                <Input

                                                    id="end_date"
                                                    name="end_date"
                                                    placeholder="end_date"
                                                    type="date"
                                                    invalid={Boolean(errors?.end_date)}
                                                    onChange={handleChange}
                                                    value={state?.end_date}

                                                />
                                                <div className="invalid-feedback">
                                                    {errors?.end_date}
                                                </div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="min_deposit_amount"
                                                >
                                                    Minimum Deposit Amount
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="min_deposit_amount"
                                                    name="min_deposit_amount"
                                                    invalid={Boolean(errors?.min_deposit_amount)}
                                                    onChange={handleChange}
                                                    value={state?.min_deposit_amount}
                                                />
                                                <div className="invalid-feedback">{errors?.min_deposit_amount}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="amount"
                                                >
                                                    Amount
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="amount"
                                                    name="amount"
                                                    invalid={Boolean(errors?.amount)}
                                                    onChange={handleChange}
                                                    value={state?.amount}
                                                />
                                                <div className="invalid-feedback">{errors?.amount}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="max_offer_amount"
                                                >
                                                    Maximum Offer Amount
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="max_offer_amount"
                                                    name="max_offer_amount"
                                                    invalid={Boolean(errors?.max_offer_amount)}
                                                    onChange={handleChange}
                                                    value={state?.max_offer_amount}
                                                />
                                                <div className="invalid-feedback">{errors?.max_offer_amount}</div>
                                            </Col>

                                        </div>


                                        <Button
                                            color="primary"
                                            type="submit"

                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Edit;