import React, { useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    FormGroup,
    Row,
    Col,
} from "reactstrap";
import store from "../../../redux/Store";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
    updatePlayer,
    getPlayerByIDs
} from "../../../redux/actions/PlayerMasterAction";
import {
    getUserByIDs,
    updateUser
} from "../../../redux/actions/UserActions";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { getSportView } from "../../../redux/actions/SportMasterAction";
import { getPlayerTypeView } from "../../../redux/actions/PlayerTypeMasterAction";
import {
    useParams,
} from "react-router-dom";
const UserEdit = () => {
    const dispatch = useDispatch();
    const { userReducer: { errors, updateStatus, userByID }, sportMasterReducer: { sport }, playerTypeMasterReducer: { playertype }, AuthReducer: { auth } } = useSelector(state => state);
    const [state, setState] = React.useState({
        id : id,
        name : "",
        email : "",
        mobile : "",
        pincode : "",
        city : "",
        gender : "",
        state : "",
        dob : "",
        street : "",
        house : "",
        landmark : "",
        country : ""
    })
    const { id } = useParams();
    useEffect(() => {
        var data = {
            user_id :id
        };
        dispatch(getUserByIDs(auth?.token, data))
        setTimeout(() => {
            var user = store.getState().userReducer.userByID?.[0];
            setState({
                ...state,
                name : user?.name,
                email : user?.email,
                mobile : user?.mobile,
                pincode : user?.pincode,
                city : user?.city,
                gender : user?.gender,
                state : user?.state,
                dob : user?.dob,
                street : user?.street,
                house : user?.house,
                landmark : user?.landmark,
                country : user?.country,
                id : user?.id
            })
        }, 1000);
    }, [])
    useEffect(() => {
        dispatch(getSportView(auth?.token))
        dispatch(getPlayerTypeView(auth?.token))
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateUser(id, auth?.token, state))
        setTimeout(() => {
            var checkUser = store.getState().userReducer.checkUser;
            if(checkUser[0]==0){
                toast.error("User Exists!!");
            }else{
                toast.success("Updated Successfully!!")
            }
        }, 1000);
        var data = {
            user_id :id
        };
        dispatch(getUserByIDs(auth?.token, data))
    }
    React.useEffect(() => {
        
    }, []);
    useEffect(() => {
        
    }, []);
    return (
        <>
            <SimpleHeader name="Edit User" parentName="Users Lists" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Edit User</h3>
                                </CardHeader>
                                <CardBody>

                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="name"
                                                >
                                                    Name
                                                </label>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    placeholder="Name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.name}
                                                    invalid={Boolean(errors?.name)}

                                                />
                                                <div className="invalid-feedback">{errors?.name}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.email}
                                                    invalid={Boolean(errors?.email)}

                                                />
                                                <div className="invalid-feedback">{errors?.email}</div>
                                            </Col>

                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="mobile"
                                                >
                                                    Mobile
                                                </label>
                                                <Input
                                                    id="mobile"
                                                    name="mobile"
                                                    placeholder="Mobile"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.mobile}
                                                    invalid={Boolean(errors?.mobile)}

                                                />
                                                <div className="invalid-feedback">{errors?.mobile}</div>
                                            </Col>
                                            
                                        </div>


                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="pincode"
                                                >
                                                    Pincode
                                                </label>
                                                <Input
                                                    id="pincode"
                                                    name="pincode"
                                                    placeholder="Pincode"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.pincode}
                                                    invalid={Boolean(errors?.pincode)}

                                                />
                                                <div className="invalid-feedback">{errors?.pincode}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="city"
                                                >
                                                    City
                                                </label>
                                                <Input
                                                    id="city"
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.city}
                                                    invalid={Boolean(errors?.city)}

                                                />
                                                <div className="invalid-feedback">{errors?.city}</div>
                                            </Col>

                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="mobile"
                                                >
                                                    Mobile
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="gender"
                                                    name="gender"
                                                    invalid={Boolean(errors?.gender)}
                                                    onChange={handleChange}
                                                    value={state?.gender}
                                                >
                                                    <option value="">Select Gender</option>
                                                    <option selected={state.gender=="M" ? true : false} value="M">Male</option>
                                                    <option selected={state.gender=="F" ? true : false}  value="F">Female</option>
                                                </Input>
                                                <div className="invalid-feedback">{errors?.mobile}</div>
                                            </Col>
                                            
                                        </div>

                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="state"
                                                >
                                                    State
                                                </label>
                                                <Input
                                                    id="state"
                                                    name="state"
                                                    placeholder="State"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.state}
                                                    invalid={Boolean(errors?.state)}

                                                />
                                                <div className="invalid-feedback">{errors?.state}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="dob"
                                                >
                                                    DOB
                                                </label>
                                                <Input
                                                    id="dob"
                                                    name="dob"
                                                    placeholder="DOB"
                                                    type="date"
                                                    onChange={handleChange}
                                                    value={state?.dob}
                                                    invalid={Boolean(errors?.dob)}

                                                />
                                                <div className="invalid-feedback">{errors?.dob}</div>
                                            </Col>

                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="street"
                                                >
                                                    Street
                                                </label>
                                                <Input
                                                    id="street"
                                                    name="street"
                                                    placeholder="Street"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.street}
                                                    invalid={Boolean(errors?.street)}

                                                />
                                                <div className="invalid-feedback">{errors?.street}</div>
                                            </Col>
                                            
                                        </div>

                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="house"
                                                >
                                                    House
                                                </label>
                                                <Input
                                                    id="house"
                                                    name="house"
                                                    placeholder="House"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.house}
                                                    invalid={Boolean(errors?.house)}

                                                />
                                                <div className="invalid-feedback">{errors?.house}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="dob"
                                                >
                                                    Landmark
                                                </label>
                                                <Input
                                                    id="landmark"
                                                    name="landmark"
                                                    placeholder="Landmark"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.landmark}
                                                    invalid={Boolean(errors?.landmark)}

                                                />
                                                <div className="invalid-feedback">{errors?.landmark}</div>
                                            </Col>

                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="country"
                                                >
                                                    Country
                                                </label>
                                                <Input
                                                    id="country"
                                                    name="country"
                                                    placeholder="Country"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.country}
                                                    invalid={Boolean(errors?.country)}

                                                />
                                                <div className="invalid-feedback">{errors?.country}</div>
                                            </Col>
                                            
                                        </div>
                                        
                                        
                                        <Button
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}


export default UserEdit;