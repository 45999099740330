
const initialState = {
    permissions: [],
    loading: false,
    error: {},
    groupedPermission: [],
    allowedPermissions: [],
    rolesPermission: []
}



const PermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PERMISSION_PENDING":
        case "GET_GROUPED_PERMISSION_PENDING":
        case "GET_ROLE_BY_ID_PENIDNG":
            return { ...state, loading: true }

        case "GET_PERMISSION_FULFILLED":
            return { ...state, loading: false, permissions: action.payload }

        case "GET_GROUPED_PERMISSION_FULFILLED":
            return { ...state, groupedPermission: action.payload, loading: false }

        case "GET_ROLE_BY_ID_FULFILLED":
            return { ...state, loading: false, allowedPermissions: action.user ? action.payload : state.allowedPermissions, rolesPermission: action.payload }

        case "GET_PERMISSION_REJECTED":
        case "GET_GROUPED_PERMISSION_REJECTED":
        case "GET_ROLE_BY_ID_REJECTED":
            return { ...state, errors: action.payload, loading: false }

        default:
            return state
    }
}

export default PermissionReducer