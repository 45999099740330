import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import {
    getCouponView,
    deleteCoupon
} from "../../../redux/actions/CouponActions"
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
import moment from "moment";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {

    const dispatch = useDispatch();
    const { couponReducer: { coupons, deleteStatus }, AuthReducer: { auth } } = useSelector(state => state);
    useEffect(() => {
        dispatch(getCouponView(auth?.token))
    }, [auth])
    const handleDelete = (data) => {
        dispatch(deleteCoupon(auth?.token, data?.id))
    }

    useEffect(() => {
        if (deleteStatus) {
            dispatch(getCouponView(auth?.token))
            toast.success("Deleted Successfully!!")
        }
    }, [deleteStatus])
    return (
        <>
            <SimpleHeader name="Coupon Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Coupons</h3></Col>
                                    <Col xs="4" className="text-right">
                                        <a href="/admin/create-coupon" className="btn btn-neutral" color="default" size="sm" type="button">
                                            New Coupon
                                        </a>
                                    </Col>
                                </Row>

                            </CardHeader>
                            <ToolkitProvider
                                data={coupons}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "coupon_code",
                                        text: "Coupon Code",
                                        sort: true,
                                        

                                    },

                                    {
                                        dataField: "coupon_type",
                                        text: "Coupon Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "start_date",
                                        text: "Start Date",
                                        sort: true,
                                    },
                                    {
                                        dataField: "end_date",
                                        text: "End Date",
                                        sort: true,
                                    },
                                    {
                                        dataField: "amount",
                                        text: "Amount",
                                        sort: true,
                                    },
                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {

                                            return (
                                                <>
                                                    
                                                    <a href={`/admin/edit-coupon/${row?.id}`} type="button" className="btn btn-neutral"
                                                        color="facebook">
                                                        Edit
                                                    </a>
                                                    <Button color="danger" outline type="button" onClick={() => handleDelete(row)}>
                                                        Delete
                                                    </Button>
                                                </>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>


            </Container>
        </>
    )
}

export default Index;

