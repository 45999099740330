import React, { useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { createContest } from "../../../redux/actions/ContestMasterAction";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { getFixtureView } from "../../../redux/actions/FixtureMasterAction";
import moment from "moment";
const ContestCreate = () => {
    const dispatch = useDispatch();
    const { constestMasterReducer: { errors, createStatus }, fixtureMasterReducer: { fixtures }, AuthReducer: { auth } } = useSelector(state => state);
    const [state, setState] = React.useState({
        contestName: "",
        fixtureId: "",
        entry: "",
        spots: "",
        pricePool: "",
        contestTypeID: "",
        maxJoin: "",
        amount: "",
        auto_create:0,
        type : ""
    })

    useEffect(() => {
        dispatch(getFixtureView(auth?.token))
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var maxJoin = state.maxJoin;
        var spots = state.spots;
        var contestTypeID = state.contestTypeID;
        if(contestTypeID==2){
            var maxJoinCheck1 = (spots*10)/100;
            var maxJoinCheck2 = 20;
            if(maxJoin>maxJoinCheck1 || maxJoin>maxJoinCheck2){
                alert("max join should be 10% of spots and less than 20!!");
                return false;
            }
        }
        dispatch(createContest(auth?.token, state))
    }
    React.useEffect(() => {
        if (createStatus) {
            toast.success("Added Successfully!!")
            setState({
                ...state,
                contestName: "",
                fixtureId: "",
                entry: "",
                spots: "",
                pricePool: "",
                contestTypeID: "",
                maxJoin: "",
                amount: "",
                auto_create:0,
                type : ""
            })
        }
    }, [createStatus])
    useEffect(() => {
        if (state.entry && state.spots) {
            setState({
                ...state,
                pricePool: (state.entry * state.spots) * 80 / 100,
                amount: state.entry * state.spots
            })
        }
    }, [state.entry, state.spots])
    useEffect(() => {
        if (state.contestTypeID == 1) {
            setState({
                ...state,
                maxJoin: 1,

            })
        }
    }, [state.contestTypeID])

    
    return (
        <>
            <SimpleHeader name="Insert Contest" parentName="Contest Lists" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Create Contest</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="fixtureId"
                                                >
                                                    Fixture
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="fixtureId"
                                                    name="fixtureId"
                                                    invalid={Boolean(errors?.fixtureId)}
                                                    onChange={handleChange}
                                                    value={state?.fixtureId}
                                                >
                                                    <option>Select Fixture</option>
                                                    {fixtures.filter(vs => vs.matchStatus == 'NS')?.map((v, i) => (
                                                        <option value={v?.id} key={i}>{v?.league?.name + ' / ' + v?.localteam?.name + ' / ' + v?.visitorteam?.name + ' / ' + moment(v?.startingAt).format("YYYY-MM-DD hh:mm:ss a")}</option>
                                                    ))}

                                                </Input>

                                                <div className="invalid-feedback">{errors?.fixtureId}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="contestName"
                                                >
                                                    Contest Name
                                                </label>
                                                <Input
                                                    id="contestName"
                                                    name="contestName"
                                                    placeholder="Contest Name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.contestName}
                                                    invalid={Boolean(errors?.contestName)}

                                                />
                                                <div className="invalid-feedback">{errors?.contestName}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="entry"
                                                >
                                                    Entry
                                                </label>
                                                <Input
                                                    id="entry"
                                                    name="entry"
                                                    placeholder="entry"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.entry}
                                                    invalid={Boolean(errors?.entry)}

                                                />
                                                <div className="invalid-feedback">{errors?.entry}</div>
                                            </Col>

                                        </div>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="spots"
                                                >
                                                    Spots
                                                </label>
                                                <Input

                                                    id="spots"
                                                    name="spots"
                                                    placeholder="spots"
                                                    type="text"
                                                    invalid={Boolean(errors?.spots)}
                                                    onChange={handleChange}
                                                    value={state?.spots}

                                                />
                                                <div className="invalid-feedback">
                                                    {errors?.spots}
                                                </div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="pricePool"
                                                >
                                                    PricePool
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="pricePool"
                                                    name="pricePool"
                                                    invalid={Boolean(errors?.pricePool)}
                                                    onChange={handleChange}
                                                    value={state?.pricePool}
                                                />
                                                <div className="invalid-feedback">{errors?.pricePool}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="contestTypeID"
                                                >
                                                    Contest Type
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="contestTypeID"
                                                    name="contestTypeID"
                                                    invalid={Boolean(errors?.contestTypeID)}
                                                    onChange={handleChange}
                                                    value={state?.contestTypeID}
                                                >
                                                    <option>Select Contest Type</option>
                                                    <option value={1}>Single Entry</option>
                                                    <option value={2}>Multi Entry</option>
                                                    {/* <option value={3}>Single Winner</option>
                                                    <option value={4}>Multi Winner</option>
                                                    <option value={5}>Guaranteed</option>
                                                    <option value={6}>Flexible</option> */}
                                                </Input>
                                                <div className="invalid-feedback">{errors?.contestTypeID}</div>
                                            </Col>
                                        </div>

                                        <div className="form-row">
                                            {state.contestTypeID == 2 ? (
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="maxJoin"
                                                    >
                                                        Max Join
                                                    </label>
                                                    <Input

                                                        id="maxJoin"
                                                        name="maxJoin"
                                                        placeholder="maxJoin"
                                                        type="text"
                                                        invalid={Boolean(errors?.maxJoin)}
                                                        onChange={handleChange}
                                                        value={state?.maxJoin}

                                                    />
                                                    <div className="invalid-feedback">
                                                        {errors?.maxJoin}
                                                    </div>
                                                </Col>
                                            ) : null}

                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="amount"
                                                >
                                                    Amount
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="amount"
                                                    name="amount"
                                                    invalid={Boolean(errors?.amount)}
                                                    onChange={handleChange}
                                                    value={state?.amount}
                                                />
                                                <div className="invalid-feedback">{errors?.discount}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="auto_create"
                                                >
                                                    Auto Create
                                                </label>
                                                <Input
                                                    id="auto_create"
                                                    name="auto_create"
                                                    placeholder="auto create"
                                                    type="select"
                                                    onChange={handleChange}
                                                    value={state?.auto_create}
                                                    invalid={Boolean(errors?.auto_create)}
                                                    className="custom_select"

                                                >
                                                    <option value={0}>Disable</option>
                                                    <option value={1}>Enable</option>
                                                </Input>
                                                <div className="invalid-feedback">{errors?.auto_create}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="type"
                                                >
                                                    Price Type
                                                </label>
                                                <Input
                                                    id="type"
                                                    name="type"
                                                    placeholder="type"
                                                    type="select"
                                                    onChange={handleChange}
                                                    value={state?.type}
                                                    invalid={Boolean(errors?.type)}
                                                    className="custom_select"

                                                >
                                                    <option value={0}>Select Option</option>
                                                    <option value={"flat"}>Flat</option>
                                                    <option value={"percentage"}>Percentage</option>
                                                </Input>
                                                <div className="invalid-feedback">{errors?.type}</div>
                                            </Col>

                                        </div>
                                        <Button
                                            
                                            color="primary"
                                            type="submit"

                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default ContestCreate;