import axios from "axios";

export const getPlayerCreditDebitAmountView = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_PENDING1"});
        const response = await axios('/api/admin/v1/player-credit-debit-list',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        console.log(response);
        
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_FULFILLED1",payload:[]})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_REJECTED1",payload:error});
    }
}

export const creditUserWallet = (token, data) => async(dispatch) => {
    try{
        
        dispatch({type:"CREDIT_USER_WALLET_PENDING"});
        const response = await axios.post(`/api/admin/v1/credit-user-wallet`,data,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        console.log(response);
        
        dispatch({type:"CREDIT_USER_WALLET_FULLFILLED",payload:[]})
    }catch(error) {
        console.log(error)
        dispatch({type:"CREDIT_USER_WALLET_REJECTED",payload:error});
    }
}

export const debitUserWallet = (token, data) => async(dispatch) => {
    try{
        
        dispatch({type:"DEBIT_USER_WALLET_PENDING"});
        const response = await axios.post(`/api/admin/v1/debit-user-wallet`,data,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        console.log(response);
        
        dispatch({type:"DEBIT_USER_WALLET_FULLFILLED",payload:[]})
    }catch(error) {
        console.log(error)
        dispatch({type:"DEBIT_USER_WALLET_REJECTED",payload:error});
    }
}

export const getUserWallet = (token,data) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_PENDING"});
        const response = await axios.post(`/api/admin/v1/player-credit-debit-list`,data,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        console.log(response);
        
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_FULFILLED",payload:response?.data?.data})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_PLAYER_CREDIT_DEBIT_AMOUNT_REJECTED",payload:error});
    }
}


export const createPlayer = (token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"CREATE_PLAYER_PENDING"})
        const response = await axios.post(`/api/admin/v1/insert-player`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "CREATE_PLAYER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"CREATE_PLAYER_REJECTED",payload:error})
    }
}

export const updatePlayer = (id,token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"UPDATE_PLAYER_PENDING"})
        const response = await axios.put(`/api/admin/v1/update-player/${id}`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "UPDATE_PLAYER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"UPDATE_PLAYER_REJECTED",payload:error})
    }
}

export const deletePlayer = (token, id) => async (dispatch) => {
    try{
        dispatch({ type: "DELETE_PLAYER_PENDING" });
        const response = await axios.delete(`/api/admin/v1/delete-player/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "DELETE_PLAYER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
        dispatch({ type: "DELETE_PLAYER_REJECTED", payload: error });
    }
}

export const getPlayerByIDs = (token,id) => async(dispatch) => {
    try{
        dispatch({type:"GET_PLAYER_BY_ID_PENDING"});
        const response = await axios(`/api/admin/v1/player-by-id/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
       
        dispatch({ type: "GET_PLAYER_BY_ID_FULFILLED", payload: response.data?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: "GET_PLAYER_BY_ID_REJECTED", payload: error });
    }
}

