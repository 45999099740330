const initialState = {
    fixtures:[],
    loading:false,
    errors:{}
}

const fixtureMasterReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_FIXTURES_PENDING":
            return {...state,loading:true};
        
        case "GET_FIXTURES_FULFILLED":
            
            return {
                ...state,
                loading:false,
                fixtures:action.payload
            };

        case "GET_FIXTURES_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default fixtureMasterReducer;