const initialState = {
    score:[],
    loading:false,
    errors:{}
}

const scoreMasterReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_SCORE_PENDING":
            return {...state,loading:true};
        
        case "GET_SCORE_FULFILLED":
            
            return {
                ...state,
                loading:false,
                score:action.payload
            };

        case "GET_SCORE_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default scoreMasterReducer;