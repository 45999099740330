import React, { useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import { useSelector } from "react-redux";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

const Dashboard = () => {
  const {
    AuthReducer: { isAuthenticated },
  } = useSelector((state) => state);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const navigate = useNavigate();
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);
  return (
    <>
      <div>
        <div>
          <div className="card">
            <h5 className="ml-3 mt-2">My Dashboard</h5>
            <hr />
            <div>
              <div className="col-md-12">
              <div class="row">
              <div class="col-md-6 col-xl-6"><div class="bg-gradient-primary border-0 card"><div class="card-body"><div class="row"><div class="col"><h5 class="text-uppercase text-muted mb-0 text-white card-title">Registered Players</h5><span class="h2 font-weight-bold mb-0 text-white">17527</span></div></div></div></div></div>

              <div class="col-md-6 col-xl-6"><div class="bg-gradient-danger border-0 card"><div class="card-body"><div class="row"><div class="col"><h5 class="text-uppercase text-muted mb-0 text-white card-title">Registered Users</h5><span class="h2 font-weight-bold mb-0 text-white">44</span></div></div></div></div></div>
              </div>

               
              </div>
             
             
             
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="widget blue-bg">
                      <div className="row">
                        <div class="col-xs-12 text-center">
                          <span className="text-center">Registration</span>
                          <h2 className="font-bold text-p">12</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget navy-bg">
                      <div className="row">
                        <div class="col-xs-12 text-center">
                          <span className="text-center"> Deposit Amount </span>
                          <h2 className="font-bold text-p">10</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget danger-bg">
                      <div className="row">
                        <div class="col-xs-12 text-center">
                          <span className="text-center"> Withdraw Amount </span>
                          <h2 className="font-bold text-p">0.00</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget blue-bg">
                      <div className="row">
                        <div class="col-xs-12 text-center">
                          <span className="text-center text-pp">
                            {" "}
                            Admin Profit{" "}
                          </span>
                          <h2 className="font-bold text-p mx-auto">20.00</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <>
              <div class="row"><div class="col-xl-6"><div class="row"><div class="col"><div class="card"><div class="border-0 card-header"><div class="align-items-center row"><div class="col"><h3 class="mb-0">Today Matches</h3></div></div></div><div class="table-responsive"><table class="align-items-center table-flush table"><thead class="thead-light"><tr><th scope="col" class="d-flex align-items-center justify-content-center">Match</th><th scope="col">Status</th><th scope="col">Time</th><th scope="col"></th></tr></thead><tbody class="list"><tr><td><div class="d-flex align-items-center justify-content-center"><div class="align-items-center media"><a class="avatar" href="#pablo"><img alt="..." src="https://cdn.sportmonks.com/images/cricket/teams/4/36.png"/></a><div class="mx-2 media"><span class="name mb-0 text-sm">AUS</span></div></div><span class="mx-2">VS</span><div class="align-items-center media"><div class="mx-2 media"><span class="name mb-0 text-sm">WI</span></div><a class="avatar" href="#pablo"><img alt="..." src="https://cdn.sportmonks.com/images/cricket/teams/11/43.png"/></a></div></div></td><td>2nd Innings</td><td>2024-02-04T03:30:00.000Z</td></tr></tbody></table></div></div></div></div></div><div class="col-xl-6"><div class="row"><div class="col"><div class="card"><div class="border-0 card-header"><div class="align-items-center row"><div class="col"><h3 class="mb-0">Today Transactions</h3></div></div></div><div class="table-responsive"><table class="align-items-center table-flush table"><thead class="thead-light"><tr><th scope="col">User</th><th scope="col">Amount</th><th scope="col">Status</th><th scope="col">Type</th><th scope="col"></th></tr></thead><tbody class="list"></tbody></table></div></div></div></div></div></div>
              </>

              
              <span className="ml-3"> Present Games in Server</span>

              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover dataTables">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Game ID</th>
                        <th>Start Date Time</th>
                        <th>Game Status</th>
                        <th>Passed No</th>
                        <th>Running No</th>
                        <th>Total Passed Nos</th>
                        <th>Persons Joined</th>
                        <th>Total Tickets</th>
                        <th>Amount Collected</th>
                        <th>Rounds Completed</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="ng-scope">
                        <td className="ng-binding">1</td>
                        <td className="ng-binding">87407</td>
                        <td className="ng-binding">2023-11-23 23:22:00</td>
                        <td className="ng-binding">Waiting To Start</td>
                        <td className="ng-binding"></td>
                        <td className="ng-binding"></td>
                        <td className="ng-binding">0</td>
                        <td className="ng-binding">0</td>
                        <td className="ng-binding">0</td>
                        <td className="ng-binding">0</td>
                        <td className="ng-binding">0</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-xs ng-scope"
                          >
                            Mark As Cancelled
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>S.No</th>
                        <th>Game ID</th>
                        <th>Start Date Time</th>
                        <th>Game Status</th>
                        <th>Passed No</th>
                        <th>Running No</th>
                        <th>Total Passed Nos</th>
                        <th>Persons Joined</th>
                        <th>Total Tickets</th>
                        <th>Amount Collected</th>
                        <th>Rounds Completed</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
