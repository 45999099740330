import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import {
    getContestView,
    deleteContest
} from "../../../redux/actions/ContestMasterAction"
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";
import moment from "moment";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const socket = useMemo(() =>
        io("https://aioprod.thebyteiq.com/", {
            cors: {
                origins: '*:*',
                methods: ["GET", "POST"],
                credentials: true,
            },
        })
        , []);
    const dispatch = useDispatch();
    const { constestMasterReducer: { constests, deleteStatus }, AuthReducer: { auth } } = useSelector(state => state);
    useEffect(() => {
        dispatch(getContestView(auth?.token))
    }, [auth])
    const handleDelete = (data) => {
        dispatch(deleteContest(auth?.token, data?.id))

    }

    useEffect(() => {
        if (deleteStatus) {
            dispatch(getContestView(auth?.token))
            toast.success("Deleted Successfully!!")
        }
    }, [deleteStatus])
    useEffect(() => {
        console.log(constests)
       // console.log("socket", socket)
        socket.emit("subscribe", { room: 1 });
        socket.on("testlivescoredata", (data) => {
            // if (1 === data.room) {
           
            //}
        });

        return () => {
            socket.off("testlivescoredata");
        };
    }, [socket]);
    return (
        <>
            <SimpleHeader name="Contest Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Contests</h3></Col>
                                    <Col xs="4" className="text-right">
                                        <a href="/admin/create-contest" className="btn btn-neutral" color="default" size="sm" type="button">
                                            New Contest
                                        </a>
                                    </Col>
                                </Row>

                            </CardHeader>
                            <ToolkitProvider
                                data={constests}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "fixtureId",
                                        text: "Fixture Name",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            console.log(row);
                                            return row?.name + '/' + row?.localteamname + '/' + row?.visitorteamname + '/' +moment(row?.startingAt).format("YYYY-MM-DD hh:mm:ss a");
                                        }

                                    },

                                    {
                                        dataField: "entry",
                                        text: "Entry",
                                        sort: true,
                                    },
                                    {
                                        dataField: "spots",
                                        text: "Spot",
                                        sort: true,
                                    },
                                    {
                                        dataField: "pricePool",
                                        text: "price Pool",
                                        sort: true,
                                    },
                                    {
                                        dataField: "contestTypeID",
                                        text: "Contest Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "maxJoin",
                                        text: "Max Join",
                                        sort: true,
                                    },

                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {

                                            return (
                                                <>
                                                    <a href={`/admin/winning/${row?.id}/update`} className="btn btn-neutral" color="default" size="sm" type="button">
                                                        Create Winning
                                                    </a>
                                                    <a href={`/admin/winning/${row?.id}/edit`} type="button" className="btn btn-neutral"
                                                        color="facebook">
                                                        Edit
                                                    </a>
                                                    <Button color="danger" outline type="button" onClick={() => handleDelete(row)}>
                                                        Delete
                                                    </Button>
                                                </>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>


            </Container>
        </>
    )
}

export default Index;

