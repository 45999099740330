const initialState = {
    coupons: [],
    coupon: {},
    loading: false,
    createStatus: false,
    deleteStatus: false,
    updateStatus: false,
    errors: {}
}

const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_COUPONS_PENDING":
        case "DELETE_COUPON_PENDING":
        case "CREATE_COUPON_PENDING":
        case "GET_COUPON_BY_ID_PENDING":
        case "UPDATE_COUPON_PENDING":
            return {
                ...state,
                loading: true,
                createStatus: false,
                deleteStatus: false,
                createWinnerStatus: false,
                updateStatus: false,
            };

        case "CREATE_COUPON_FULFILLED":
            return {
                ...state,
                loading: false,
                createStatus: true,

            };
        case "DELETE_COUPON_FULFILLED":
            return {
                ...state,
                loading: false,
                deleteStatus: true,

            };

        case "GET_COUPONS_FULFILLED":

            return {
                ...state,
                loading: false,
                coupons: action.payload
            };
        case "GET_COUPON_BY_ID_FULFILLED":

            return {
                ...state,
                loading: false,
                coupon: action.payload
            };
        case "UPDATE_COUPON_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,

            };
        case "GET_COUPONS_REJECTED":
        case "CREATE_COUPON_REJECTED":
        case "DELETE_COUPON_REJECTED":
        case "GET_COUPON_BY_ID_PENDING":
        case "UPDATE_COUPON_REJECTED":
            console.log(action?.payload?.response?.data)
            return {
                ...state,
                loading: false,
                errors: action?.payload?.response?.data,
                createStatus: false,
                deleteStatus: false,
                updateStatus: false,
            };

        default:
            return state;
    }
}

export default couponReducer;