import axios from "axios";

export const getReferalSystem = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_REF_SYSTEM_PENDING"});
        const response = await axios('/api/admin/v1/get-referal-system',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        console.log(response?.data?.data);
        
        dispatch({type:"GET_REF_SYSTEM_FULFILLED",payload:response?.data?.data})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_REF_SYSTEM_REJECTED",payload:error});
    }
}


export const createPlayer = (token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"CREATE_PLAYER_PENDING"})
        const response = await axios.post(`/api/admin/v1/insert-player`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "CREATE_PLAYER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"CREATE_PLAYER_REJECTED",payload:error})
    }
}

export const upRefSystem = (token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"UPDATE_REF_SYSTEM_PENDING"})
        const response = await axios.post(`/api/admin/v1/update-referal-system`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "UPDATE_REF_SYSTEM_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"UPDATE_REF_SYSTEM_REJECTED",payload:error})
    }
}

export const deletePlayer = (token, id) => async (dispatch) => {
    try{
        dispatch({ type: "DELETE_PLAYER_PENDING" });
        const response = await axios.delete(`/api/admin/v1/delete-player/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "DELETE_PLAYER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
        dispatch({ type: "DELETE_PLAYER_REJECTED", payload: error });
    }
}

export const getPlayerByIDs = (token,id) => async(dispatch) => {
    try{
        dispatch({type:"GET_PLAYER_BY_ID_PENDING"});
        const response = await axios(`/api/admin/v1/player-by-id/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
       
        dispatch({ type: "GET_PLAYER_BY_ID_FULFILLED", payload: response.data?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: "GET_PLAYER_BY_ID_REJECTED", payload: error });
    }
}

