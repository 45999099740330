import React, { useEffect,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import { 
    getUsersWithdrwalView
 } from "../../../redux/actions/UserActions";
 import { 
    upWithdrawal
 } from "../../../redux/actions/WithdrawalAction";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
import NoImage from "../../../assets/img/download.png"
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const [modal,setModal] = useState(false);
    const [url,setUrl] = useState({});
    const [id, setId] = useState("");
    var [bankName, setbankName] = useState("");
    var [accountNo, setaccountNo] = useState("");
    var [amount, setamount] = useState("");
    var [withDrawlStatus, setwithDrawlStatus] = useState("");
    var [name, setName] = useState("");
    const [update_btn_status, setUpdateBtnstatus] = useState("");
    var [mobile, setMobile] = useState("");
    var [userstate, setUserState] = useState("");
    const [current, setCurrent] = useState("");
    var [country, setCountry] = useState("");
    const [remark_field, setRemarkField] = useState(false);
    const [ref_field, setRefField] = useState(false);
    const [remark, setRemark] = useState("");
    const [refId, setRefId] = useState("");

    const dispatch = useDispatch();
    const { userReducer: { withdrwals }, AuthReducer: { auth } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getUsersWithdrwalView(auth?.token))
    }, [auth])
    
    const handleModal = (row) => {
        setUrl(row)
        setModal(!modal);
        
    };

    const viewWithDraw = (row) => {
        console.log(row);
        setId(row?.id);
        setModal(true);
        setName(row?.user?.name);
        setMobile(row?.user?.mobile);
        setUserState(row?.user?.state);
        setCountry(row?.user?.country);
        setbankName(row?.user?.bankName);
        setaccountNo(row?.user?.accountNo);
        setamount(row?.amount);
        setwithDrawlStatus(row?.withDrawlStatus);
    }

    const clickToComplete = () => {
        clearFields();
        setRemarkField(true);
        setRefField(true);
        setUpdateBtnstatus(true);
        setCurrent("completed");
    }

    const clickToReject = () => {
        clearFields();
        setRemarkField(true);
        setUpdateBtnstatus(true);
        setCurrent("rejected");
    }

    const updateWithdrawal = () => {
        if(remark==""){
            alert("Please fill all the fields to update withdrawal!!");
            return false;
        }else{
            var data = {
                withDrawlStatus : current,
                remark : remark,
                refId : current=="completed" ? refId : "",
                id : id
            };
            dispatch(upWithdrawal(auth?.token, data));
            setModal(false);
            clearFields();
            setTimeout(() => {
                dispatch(getUsersWithdrwalView(auth?.token,data))
            }, 200);
            
        }
    }

    const clearFields = () => {
        setRefField(false);
        setRemarkField(false);
        setUpdateBtnstatus(false);
    }

    const GoBack = () => {
        setUpdateBtnstatus(false);
        setRefField(false);
        setRemarkField(false);
    }
    return (
        <>
            <SimpleHeader name="Withdrawal Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Withdrawal</h3></Col>
                                    {/* <Col xs="4" className="text-right">
                                        <a href="/admin/create-player" className="btn btn-neutral" color="default" size="sm" type="button">
                                           New Player
                                        </a >
                                    </Col> */}
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                data={withdrwals}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.name",
                                        text: "Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "refId",
                                        text: "Ref Id",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.username",
                                        text: "User Name",
                                        sort: true,
                                    },
                                    // {
                                    //     dataField: "email",
                                    //     text: "Email",
                                    //     sort: true,
                                        
                                    // },
                                    {
                                        dataField: "user.mobile",
                                        text: "Mobile",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.state",
                                        text: "State",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.city",
                                        text: "City",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.gender",
                                        text: "Gender",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return row.gender=='m' ? 'Male' : 'Female'; 
                                        },
                                    },
                                    {
                                        dataField: "user.aadhaarNumber",
                                        text: "Adhaar No",
                                        sort: true,
                                    },
                                    
                                    {
                                        dataField: "user.bankName",
                                        text: "Bank Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.accountNo",
                                        text: "Account Number",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.branchName",
                                        text: "Branch Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "user.ifscCode",
                                        text: "IFSC Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "amount",
                                        text: "Requested Amount",
                                        sort: true,
                                    },
                                    {
                                        dataField: "remark",
                                        text: "Remark",
                                        sort: true,
                                    },
                                    {
                                        dataField: "withDrawlStatus",
                                        text: "Status",
                                        formatter: (cell, row) => {
                                           if(row?.withDrawlStatus=="completed"){
                                            return (
                                                <span color="green" className="text-success">Completed</span>
                                            );
                                           }
                                           if(row?.withDrawlStatus=="rejected"){
                                            return (
                                                <span color="red" className="text-danger">Rejected</span>
                                            );
                                           }
                                           if(row?.withDrawlStatus=="success"){
                                            return (
                                                <span color="green" className="text-success">Success</span>
                                            );
                                           }
                                           if(row?.withDrawlStatus=="pending"){
                                            return (
                                                <span color="orange" className="text-warning">Pending</span>
                                            );
                                           }
                                        },
                                    },
                                    {
                                        dataField: "",
                                        text: "View",
                                        formatter: (cell, row) => {
                                            return (<a onClick={() => viewWithDraw(row)} className="btn btn-success" type="button" >
                                                        View
                                                    </a>);
                                        },
                                    },

                                    
                                    

                                    // {
                                    //     dataField: "",
                                    //     text: "Action",
                                    //     formatter: (cellContent, row) => {
                                            
                                    //         return (
                                    //             <>
                                    //                 {/* <a href={`/admin/edit-player/${row?.id}`} className="btn btn-neutral" type="button" >
                                    //                     Edit
                                    //                 </a>
                                    //                 <Button color="danger" outline type="button" onClick={()=>handleDelete(row)}>
                                    //                     Delete
                                    //                 </Button> */}
                                    //             </>

                                    //         );
                                    //     },
                                    // },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   
                    <div style={{"padding":"40px"}} class="row">
                        {/* <p style={{"color":"red"}}>
                            {error}
                        </p> */}
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="text-danger ng-binding" ng-bind-html="error_message"></span>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name : </label>
                                        <b> {name}</b>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mobile : </label>
                                        <b> {mobile}</b>
                                        
                                    </div>
                                </div>   
                            </div>

                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>State : </label>
                                        <b> {userstate}</b>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Country : </label>
                                        <b> {country}</b>
                                        
                                    </div>
                                </div>   
                            </div>

                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Bank Name : </label>
                                        <b> {bankName}</b>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Ac/No : </label>
                                        <b> {accountNo}</b>
                                        
                                    </div>
                                </div>   
                            </div>

                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Requested Amount : </label>
                                        <b> {amount}</b>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Status : </label>
                                        {withDrawlStatus=="completed" || withDrawlStatus=="success" ? 
                                            <b style={{"color":"green"}}> {withDrawlStatus}</b> : 
                                            <b style={{"color":"red"}}> {withDrawlStatus}</b>    
                                        }
                                        
                                        
                                    </div>
                                </div>   
                            </div>

                            <div class="row"> 
                            {
                                ref_field==true ? <div class="col-md-6">
                                <div class="form-group">
                                    <label>Ref Id : </label>
                                    <input onChange={(e) => setRefId(e.target.value)} className="form-control" type="text" />
                                    
                                </div>
                            </div>  : ""
                            }
                                    
                            {
                                remark_field==true ? <div class="col-md-6">
                                <div class="form-group">
                                    <label>Remark :</label>
                                    <textarea onChange={(e) => setRemark(e.target.value)}  className="form-control" name="" id="" cols="20" rows="5"></textarea>
                                    
                                </div>
                            </div>   : ""
                            }
                                     
                                </div>
                            


                            
                        </div>
                    </div>
                    <div class="col-md-12" style={{"margin-bottom":"15px"}}> 
                    {
                        update_btn_status==false ? 
                        <>
                        <button onClick={() => clickToComplete()} class="btn btn-success"><b>Click to Complete</b></button>
                    <button onClick={() => clickToReject()} class="btn btn-danger"><b>click to Reject</b></button>
                        </> : 
                        <>
                        <button onClick={() => updateWithdrawal()} class="btn btn-warning"><b>Update</b></button>
                        <button onClick={() => GoBack()} class="btn btn-danger"><b>Back</b></button>
                        </>
                    }
                    
                    
                    </div>
                   
                

                </Modal>


            </Container>
        </>
    )
}

export default Index;

