import axios from "axios"
export const login = (data) => async(dispatch) => {
    try {
        dispatch({type:"LOGIN_PENDING"})
        const response = await axios.post("/api/admin/v1/admin/login",data)
        localStorage.setItem("fantasy_admin",JSON.stringify(response.data))
        dispatch({type:"LOGIN_FULFILLED",payload:response.data})
        
    } catch (error) {
        dispatch({type:"LOGIN_FAILED",payload:error.response.data})
    }
} 

export const logout = () => async(dispatch) =>{
   localStorage.removeItem("fantasy_admin")
   dispatch({type:"LOG_OUT_FULFILLED"})
}

