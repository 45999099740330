import React, { useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    FormGroup,
    Row,
    Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { createPlayer } from "../../../redux/actions/PlayerMasterAction";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { getSportView } from "../../../redux/actions/SportMasterAction";
import { getPlayerTypeView } from "../../../redux/actions/PlayerTypeMasterAction";

const PlayerCreate = () => {
    const dispatch = useDispatch();
    const { playerMasterReducer: { errors, createStatus }, sportMasterReducer: { sport }, playerTypeMasterReducer: { playertype }, AuthReducer: { auth } } = useSelector(state => state);
    const [state, setState] = React.useState({
        sportId: "",
        countryId: "",
        playerTypeId: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        battingstyle: "",
        bowlingstyle: "",
        fantacyPoint: ""
    })

    useEffect(() => {
        dispatch(getSportView(auth?.token))
        dispatch(getPlayerTypeView(auth?.token))
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createPlayer(auth?.token, state))
    }
    React.useEffect(() => {
        if (createStatus) {
            toast.success("Added Successfully!!")
        }
    }, [createStatus])
    return (
        <>
            <SimpleHeader name="Insert Player" parentName="Player Lists" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Create Player</h3>
                                </CardHeader>
                                <CardBody>

                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="sportId"
                                                >
                                                    Sport
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="sportId"
                                                    name="sportId"
                                                    invalid={Boolean(errors?.sportId)}
                                                    onChange={handleChange}
                                                    value={state?.sportId}
                                                >
                                                    <option>Select Sport</option>
                                                    {sport?.map((v, i) => (
                                                        <option value={v?.id} key={i}>{v?.name}</option>
                                                    ))}
                                                </Input>
                                                <div className="invalid-feedback">{errors?.sportId}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="countryId"
                                                >
                                                    Country
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="countryId"
                                                    name="countryId"
                                                    invalid={Boolean(errors?.countryId)}
                                                    onChange={handleChange}
                                                    value={state?.countryId}
                                                >
                                                    <option>Select Country</option>
                                                    <option>52126</option>
                                                </Input>
                                                <div className="invalid-feedback">{errors?.countryId}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="playerTypeId"
                                                >
                                                    Player Type
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="playerTypeId"
                                                    name="playerTypeId"
                                                    invalid={Boolean(errors?.playerTypeId)}
                                                    onChange={handleChange}
                                                    value={state?.playerTypeId}
                                                >
                                                    <option>Select Country</option>
                                                    {playertype?.map((v, i) => (
                                                        <option value={v?.id} key={i}>{v?.name}</option>
                                                    ))}
                                                </Input>
                                                <div className="invalid-feedback">{errors?.playerTypeId}</div>
                                            </Col>
                                        </div>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="firstName"
                                                >
                                                    First Name
                                                </label>
                                                <Input
                                                    id="firstName"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.firstName}
                                                    invalid={Boolean(errors?.firstName)}

                                                />
                                                <div className="invalid-feedback">{errors?.firstName}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="lastName"
                                                >
                                                    Last Name
                                                </label>
                                                <Input
                                                    id="lastName"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.lastName}
                                                    invalid={Boolean(errors?.lastName)}

                                                />
                                                <div className="invalid-feedback">{errors?.lastName}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="dateOfBirth"
                                                >
                                                    DOB
                                                </label>
                                                <FormGroup className="row">
                                                    <Col md="12">
                                                        <Input
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            type="date"
                                                            onChange={handleChange}
                                                            value={state?.dateOfBirth}
                                                            invalid={Boolean(errors?.dateOfBirth)}

                                                        />
                                                        <div className="invalid-feedback">{errors?.dateOfBirth}</div>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom04"
                                                >
                                                    Gender
                                                </label>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="customRadio6_m"
                                                                name="gender"
                                                                onChange={handleChange}
                                                                type="radio"
                                                                value="m"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio6_m"
                                                            >
                                                                Male
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="customRadio6_f"
                                                                name="gender"
                                                                onChange={handleChange}
                                                                type="radio"
                                                                value="f"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio6_f"
                                                            >
                                                                Female
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="battingstyle"
                                                >
                                                    Batting Style
                                                </label>
                                                <Input
                                                    id="battingstyle"
                                                    name="battingstyle"
                                                    placeholder="Batting Style"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.battingstyle}
                                                    invalid={Boolean(errors?.battingstyle)}

                                                />
                                                <div className="invalid-feedback">{errors?.battingstyle}</div>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="bowlingstyle"
                                                >
                                                    Bowling Style
                                                </label>
                                                <Input
                                                    id="bowlingstyle"
                                                    name="bowlingstyle"
                                                    placeholder="Bowling Style"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.bowlingstyle}
                                                    invalid={Boolean(errors?.bowlingstyle)}

                                                />
                                                <div className="invalid-feedback">{errors?.bowlingstyle}</div>
                                            </Col>
                                        </div>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="fantacyPoint"
                                                >
                                                    Fantacy Point
                                                </label>
                                                <Input
                                                    id="fantacyPoint"
                                                    name="fantacyPoint"
                                                    placeholder="Fantacy Credit"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={state?.fantacyPoint}
                                                    invalid={Boolean(errors?.fantacyPoint)}

                                                />
                                                <div className="invalid-feedback">{errors?.fantacyPoint}</div>
                                            </Col>
                                        </div>
                                        <Button
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}


export default PlayerCreate;