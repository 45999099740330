const initialState = {
    referal_system: [],
    loading: false,
    createStatus: false,
    deleteStatus: false,
    updateStatus: false,
    playerByID: {},
    errors: {}
}

const ReferalSystemReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_REF_SYSTEM_PENDING":
        case "DELETE_REF_SYSTEM_PENDING":
        case "CREATE_REF_SYSTEM_PENDING":
        case "UPDATE_REF_SYSTEM_PENDING":
        case "GET_REF_SYSTEM_BY_ID_PENDING":
            return {
                ...state,
                loading: true,
                updateStatus: false
            };

        case "CREATE_REF_SYSTEM_FULFILLED":
            return {
                ...state,
                loading: false,
                createStatus: true,
            };
        case "UPDATE_REF_SYSTEM_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,

            };

        case "DELETE_REF_SYSTEM_FULFILLED":
            return {
                ...state,
                loading: false,
                deleteStatus: true,

            };

        case "GET_REF_SYSTEM_FULFILLED":
            return {
                ...state,
                loading: false,
                referal_system: action.payload
            };
       

        case "GET_REF_SYSTEM_BY_ID_FULFILLED":
            return {
                ...state,
                loading: false,
                playerByID: action.payload
            };

        case "GET_REF_SYSTEM_REJECTED":
        case "CREATE_REF_SYSTEM_REJECTED":
        case "DELETE_REF_SYSTEM_REJECTED":
        case "UPDATE_REF_SYSTEM_REJECTED":
        case "GET_REF_SYSTEM_BY_ID_REJECTED":
            return {
                ...state,
                loading: false,
                errors: action.payload,
                createStatus: false,
                deleteStatus: false,
                updateStatus: false
            };

        default:
            return state;
    }
}

export default ReferalSystemReducer;