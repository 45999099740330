import axios from "axios";

export const getTransaction = (token) => async(dispatch) => {
    try{
        dispatch({type:"GET_TRANSACTION_LIST_PENDING"});
        const response = await axios('/api/admin/v1/user-transaction-list',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_TRANSACTION_LIST_FULFILLED",payload:response?.data?.transaction})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_TRANSACTION_LIST_REJECTED",payload:error});
    }
}