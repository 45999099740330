import axios from "axios";

export const getUsersView = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_USERS_PENDING"});
        const response = await axios('/api/admin/v1/user-list',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_USERS_FULFILLED",payload:response.data?.users})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_USERS_REJECTED",payload:error});
    }
}


export const updateUserStatus = (token, data) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_USERS_PENDING"});
        const response = await axios.post('/api/admin/v1/update-user-status',data,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_USERS_FULFILLED",payload:[]})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_USERS_REJECTED",payload:error});
    }
}

export const updateUser = (id,token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"UPDATE_USER_PENDING"})
        const response = await axios.post(`/api/admin/v1/update-user`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "UPDATE_USER_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"UPDATE_USER_REJECTED",payload:error})
    }
}

export const getUsersWithdrwalView = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_USERS_WITHDRWAL_PENDING"});
        const response = await axios('/api/admin/v1/user-withdral-request',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_USERS_WITHDRWA_FULFILLED",payload:response.data?.users})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_USERS_WITHDRWA_REJECTED",payload:error});
    }
}

export const getUserByIDs = (token,data) => async(dispatch) => {
    try{
        dispatch({type:"GET_USER_BY_ID_PENDING"});
        const response = await axios.post(`/api/admin/v1/get-user-by-id`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
       
        dispatch({ type: "GET_USER_BY_ID_FULFILLED", payload: response.data?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: "GET_USER_BY_ID_REJECTED", payload: error });
    }
}