const initialState = {
    transactions:[],
    loading:false,
    errors:{}
}


const transactionReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_TRANSACTION_LIST_PENDING":
            return {...state,loading:true};
        case "GET_TRANSACTION_LIST_FULFILLED": 
       
            return {
                ...state,
                loading:false,
                transactions:action.payload
            };
        case "GET_TRANSACTION_LIST_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default transactionReducer;