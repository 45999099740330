const initialState = {
    sport:[],
    loading:false,
    errors:{}
}

const playerMasterReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_SPORT_PENDING":
            return {...state,loading:true};
        
        case "GET_SPORT_FULFILLED":
            
            return {
                ...state,
                loading:false,
                sport:action.payload
            };

        case "GET_SPORT_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default playerMasterReducer;