import React, { useState, useEffect } from "react";
import store from "../../../redux/Store";
import {
    useParams
} from "react-router-dom";
import {
    getContestByIDs,
    createContestWinner,
    deleteContestWinner,
    updateContestWinner
} from "../../../redux/actions/ContestMasterAction";
import { useDispatch, useSelector } from 'react-redux'
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
    FormGroup,
    Input
} from "reactstrap";
import { toast } from "react-toastify";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});
const { SearchBar } = Search;
const Winnings = () => {
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const { id } = useParams();
    const [state, setState] = useState({
        contestId: id,
        rankStart: "",
        rankEnd: "",
        type: "1",
        price: 0,
        shortDescription: "test",
    });
    const [editWinner, setEditWinner] = useState(false)
    const [winnerId, setWinnerId] = useState("");
    const dispatch = useDispatch();
    const { constestMasterReducer: { constest, constests, createWinnerStatus, errors, deleteStatus, updateStatus }, AuthReducer: { auth } } = useSelector(state => state);
    useEffect(() => {
        dispatch(getContestByIDs(auth?.token, id));
        setTimeout(() => {
            var totalPrice = 0;
            var contestWinners = store.getState().constestMasterReducer.constest.contests_winners;
            console.log(contestWinners);
            totalPrice = contestWinners.reduce(function (sum, row) {
                return sum + parseInt(row.price);
            }, 0);
            if(totalPrice!=100){
                toast.error("Contest winners price percentage should be equal to 100!!");
            }
        }, 1000);
    }, [auth])
    const handleDelete = (id) => {
        dispatch(deleteContestWinner(auth?.token, id))
    }
    useEffect(() => {
        if (constest) {
            setData(constest?.contests_winners)
        }
    }, [constest, constest?.contests_winners])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }
    const pricePoolValidation = (contest, price) => {
        if (contest?.contests_winners?.length > 0) {
            console.log(contest)
            const sumOfWinnerPrice = contest?.contests_winners?.length > 0 ? contest?.contests_winners.map(i => parseInt(i.price)).reduce((a, b) => a + b) + parseInt(price) : parseInt(price);
            let f = contest?.contests_winners?.length > 0 ? contest?.contests_winners?.[0]?.rankStart : 0;
            // Storing the last item 
            let l = contest?.contests_winners?.length > 0 ? contest?.contests_winners?.[contest?.contests_winners.length - 1]?.rankEnd : 1;
            const sumOfWinnerNumber = f + l;
            const finalIndex = sumOfWinnerNumber == 0 ? 1 : sumOfWinnerNumber;
            console.log(sumOfWinnerPrice)
            console.log(l)
            console.log(f)
            console.log(contest)
            if (contest?.pricePool < sumOfWinnerPrice * sumOfWinnerNumber) {
                return 0;
            } else {
                return 1;
            }
        } else {
            if (contest?.pricePool < parseInt(price)) {
                return 0;
            } else {
                return 1;
            }
        }



    }
    const handleSubmit = (e) => {
        e.preventDefault();
       
        if (pricePoolValidation(constest, state.price) == 1) {
            if(state.rankEnd<state.rankStart){
                toast.error("Given rank values is in between previously entered ranks!!");
                return false;
            }
            dispatch(createContestWinner(auth?.token, state));
            setTimeout(() => {
                var response = store.getState().constestMasterReducer.createWinner;
                if(Object.keys(response.contestWinner).length === 0){
                    toast.error(response?.message);
                    return false;
                }else{
                    toast.success(response?.message);
                    setState({
                        ...state,
                        contestId: id,
                        rankStart: "",
                        rankEnd: "",
                        type: "",
                        price: 0,
                        shortDescription: "",
                    })
                    setModal(false);
                }
            }, 1000);
        } else {
            toast.error("Winning amount greater then PricePool Amount");
        }

    }
    React.useEffect(() => {
        if (createWinnerStatus) {
            dispatch(getContestByIDs(auth?.token, id))
            // toast.success("Added Successfully!!")
            // setState({
            //     ...state,
            //     contestId: id,
            //     rankStart: "",
            //     rankEnd: "",
            //     type: "",
            //     price: 0,
            //     shortDescription: "",
            // })
        }
    }, [createWinnerStatus])
    useEffect(() => {
        if (deleteStatus) {
            dispatch(getContestByIDs(auth?.token, id))
            toast.success("Deleted Successfully!!")
        }
    }, [deleteStatus])
    const handleModal = () => { 
        setState({
            ...state,
            contestId: id,
            rankStart: "",
            rankEnd: "",
            type: "",
            price: 0,
            shortDescription: "",
        })
        setModal(!modal); 
        setEditWinner(false) 
    };
    const handleEditModel = (data) => {

        setWinnerId(data?.id);
        setState({
            ...state,
            rankStart: data?.rank_start,
            rankEnd: data?.rank_end,
            type: data?.type,
            price: data?.price,
            shortDescription: data?.shortDescription,
        })
        setModal(true);
        setEditWinner(true)
    }
    const handleEdit = (e) => {
        e.preventDefault();
        if (pricePoolValidation(constest, state.price) == 1) {
            dispatch(updateContestWinner(winnerId, auth?.token, state))
        } else {
            toast.error("Winning amount greater then PricePool Amount");
        }

    }
    useEffect(() => {
        if (updateStatus) {
            setModal(false)
            dispatch(getContestByIDs(auth?.token, id))
            toast.success("Updated Successfully!!")
        }
    }, [updateStatus])
    return (
        <>
            <SimpleHeader name="Contest Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8">
                                        <h3 className="mb-0">Contest Winnings</h3>
                                        <h3 className="mb-0">{constest?.contestName}</h3>
                                    </Col>
                                    <Col xs="4" className="text-right">
                                        <a href="#" onClick={handleModal} className="btn btn-neutral" color="default" size="sm" type="button">
                                            New Winnings
                                        </a>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                data={data ?? []}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "price",
                                        text: "Price",
                                        sort: true,

                                    },

                                    {
                                        dataField: "rank_start",
                                        text: "Rank Start",
                                        sort: true,
                                    },
                                    {
                                        dataField: "rank_end",
                                        text: "Rank End",
                                        sort: true,
                                    },

                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {

                                            return (
                                                <>

                                                    {/* <Button color="danger" outline type="button" onClick={() => handleEditModel(row)}>
                                                        Edit
                                                    </Button> */}
                                                    <Button color="danger" outline type="button" onClick={() => handleDelete(row?.id)}>
                                                        Delete
                                                    </Button>
                                                </>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                    size="lg"
                >
                    <Card>
                        <CardHeader>Create Winner</CardHeader>
                        <form className="new-event--form">
                            <div className="modal-body">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="rankStart"
                                            >
                                                Rank Start
                                            </label>
                                            <Input
                                                type="text"
                                                id="rankStart"
                                                name="rankStart"
                                                invalid={Boolean(errors?.rankStart)}
                                                onChange={handleChange}
                                                value={state?.rankStart}
                                            />
                                            <div className="invalid-feedback">{errors?.rankStart}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="rankEnd"
                                            >
                                                Rank End
                                            </label>
                                            <Input
                                                type="text"
                                                id="rankEnd"
                                                name="rankEnd"
                                                invalid={Boolean(errors?.rankEnd)}
                                                onChange={handleChange}
                                                value={state?.rankEnd}
                                            />
                                            <div className="invalid-feedback">{errors?.rankEnd}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="price"
                                            >
                                                Price
                                            </label>
                                            <Input
                                                type="text"
                                                id="price"
                                                name="price"
                                                invalid={Boolean(errors?.price)}
                                                onChange={handleChange}
                                                value={state?.price}
                                            />
                                            <div className="invalid-feedback">{errors?.price}</div>
                                        </FormGroup>
                                    </Col>

                                    {/* <Col sm="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="type"
                                            >
                                                Price Type
                                            </label>
                                            <select
                                                name="type"
                                                aria-controls="datatable-basic"
                                                className="form-control"
                                                onChange={handleChange}
                                            >
                                                <option value="1">Percentage</option>
                                                <option value="2">Flat</option>
                                            </select>
                                            <div className="invalid-feedback">{errors?.type}</div>
                                        </FormGroup>
                                    </Col> */}
                                </Row>


                            </div>
                            <div className="modal-footer">
                                <Button
                                    type="submit"
                                    className="new-event--add sm"
                                    color="primary"
                                    onClick={editWinner ? handleEdit : handleSubmit}
                                >
                                    Add
                                </Button>
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    type="button"
                                    onClick={() => setModal(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </form>
                    </Card>


                </Modal>
            </Container>
        </>
    )
}

export default Winnings;