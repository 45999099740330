const initialState = {
    team:[],
    loading:false,
    errors:{}
}

const teamMasterReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_TEAM_PENDING":
            return {...state,loading:true};
        
        case "GET_TEAM_FULFILLED":
            
            return {
                ...state,
                loading:false,
                team:action.payload
            };

        case "GET_TEAM_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default teamMasterReducer;