import axios from "axios";

export const getFixtureView = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_FIXTURES_PENDING"});
        const response = await axios('/api/admin/v1/fixtures-lists',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_FIXTURES_FULFILLED",payload:response.data?.data})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_FIXTURES_REJECTED",payload:error});
    }
}