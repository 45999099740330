import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { AiFillEye } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidUserCircle } from "react-icons/bi";
import { AiFillGithub } from "react-icons/ai";
import { BsBagFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Details = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const toggle1 = () => setDropdownOpen1((prevState) => !prevState);
    const [modal, setModal] = useState(false);

    const toggle2 = () => setModal(!modal);
    return (
        <>
            <div className="p-md-4 p-3">
                {/* <h2>Details Page</h2> */}
                <div className="box_container">
                    <div className="row mb-md-4 mb-3">
                        <div className="col-lg-3 mb-md-0 mb-3">
                            <div className="bg-white d-flex justify-content-around align-items-center rounded-3 p-4">
                                <div className="icon_div">
                                    <FaUserAlt size={50} />
                                </div>
                                <div className="content_div">
                                    <h4 className="m-0">New Users</h4>
                                    <h1 className="fs-2 mb-0"><b>100</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-md-0 mb-3">
                            <div className="bg-white d-flex justify-content-around align-items-center rounded-3 p-4">
                                <div className="icon_div">
                                    <GiProgression size={50} />
                                </div>
                                <div className="content_div">
                                    <h4 className="m-0">Monthly Sales</h4>
                                    <h1 className="fs-2 mb-0"><b>80%</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-md-0 mb-3">
                            <div className="bg-white d-flex justify-content-around align-items-center rounded-3 p-4">
                                <div className="icon_div">
                                    <AiFillEye size={50} />
                                </div>
                                <div className="content_div">
                                    <h4 className="m-0">Impressions
                                    </h4>
                                    <h1 className="fs-2 mb-0"><b>532</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-md-0 mb-3">
                            <div className="bg-white d-flex justify-content-around align-items-center rounded-3 p-4">
                                <div className="icon_div">
                                    <FaDownload size={50} />
                                </div>
                                <div className="content_div">
                                    <h4 className="m-0">Downloads
                                    </h4>
                                    <h1 className="fs-2 mb-0"><b>113</b></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-4 mb-3">
                        <div className="col-lg-6">
                            <div className="bg-white p-md-4 p-3 m-md-0 mb-3">
                                <div className="box_header d-flex justify-content-between align-items-center pb-3">
                                    <h3 className="mb-0"><b>Purchases</b></h3>
                                    <div className="d-flex">
                                        <span>
                                            <div>
                                                <Modal isOpen={modal} toggle={toggle2}>
                                                    <ModalHeader toggle={toggle2}>PDF download</ModalHeader>
                                                    <ModalBody>
                                                        Are you Ready for pdf download
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="secondary" onClick={toggle2}>
                                                            Cancel
                                                        </Button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>

                                            <MdFileDownload size={28} color="danger" onClick={toggle2} /></span>
                                        <span>
                                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                <DropdownToggle className="bg-transparent shadow-none border-0 m-0 p-0"><SlOptionsVertical size={24} /></DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Some Action</DropdownItem>
                                                    <DropdownItem>Foo Action</DropdownItem>
                                                    <DropdownItem>Bar Action</DropdownItem>
                                                    <DropdownItem>Quo Action</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </span>
                                    </div>
                                </div>
                                <div className="box_middle">
                                    <table class="table table-striped border table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-uppercase"><b>Product</b></th>
                                                <th scope="col" className="text-uppercase"><b>Price</b></th>
                                                <th scope="col" className="text-uppercase"><b>Date</b></th>
                                                <th scope="col" className="text-uppercase"><b>State</b></th>
                                                <th scope="col" className="text-uppercase"><b>Add item</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Sony Xperia M4</th>
                                                <td>Rs 149</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-success">Completed</td>
                                                <td ><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Apple iPhone 6</th>
                                                <td>Rs 535</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-success">Completed</td>
                                                <td><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Samsung Galaxy S7	</th>
                                                <td>Rs 583</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-warning">Pending</td>
                                                <td><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">HTC One M9</th>
                                                <td>Rs 350</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-danger">Cancelled</td>
                                                <td><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sony Xperia M4</th>
                                                <td>Rs 149</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-success">Completed</td>
                                                <td><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Apple iPhone 6</th>
                                                <td>Rs 535</td>
                                                <td>Aug 10, 2023</td>
                                                <td className="text-success">Completed</td>
                                                <td><AiOutlinePlusCircle size={20} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bg-white p-md-4 p-3">
                                <div className="box_header d-flex justify-content-between align-items-center pb-3">
                                    <h3 className="mb-0"><b>Latest Commits
                                    </b></h3>
                                    <div className="d-flex">
                                    <span>
                                            <div>
                                                <Modal isOpen={modal} toggle={toggle2}>
                                                    <ModalHeader toggle={toggle2}>PDF download</ModalHeader>
                                                    <ModalBody>
                                                        Are you Ready for pdf download
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="secondary" onClick={toggle2}>
                                                            Cancel
                                                        </Button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>

                                            <MdFileDownload size={28} color="danger" onClick={toggle2} /></span>
                                        <span>
                                            <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
                                                <DropdownToggle className="bg-transparent shadow-none border-0 m-0 p-0" direction="start"><SlOptionsVertical size={24} /></DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem>Some Action</DropdownItem>
                                                    <DropdownItem>Foo Action</DropdownItem>
                                                    <DropdownItem>Bar Action</DropdownItem>
                                                    <DropdownItem>Quo Action</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </span>
                                    </div>
                                </div>
                                <div className="box_middle">
                                    <table class="table table-striped border table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-uppercase"><b>User</b></th>
                                                <th scope="col" className="text-uppercase"><b>Commit</b></th>
                                                <th scope="col" className="text-uppercase"><b>Date</b></th>
                                                <th scope="col" className="text-uppercase"><b></b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <div className="d-flex">
                                                        <span className="pr-2"><BiSolidUserCircle size={30} /></span>
                                                        <h4>shahroj khan</h4>
                                                    </div>
                                                </th>
                                                <td>Topbar dropdown style</td>
                                                <td>Aug 10, 2023</td>
                                                <td><span><AiFillGithub size={26} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"> <div className="d-flex">
                                                    <span className="pr-2"><BiSolidUserCircle size={30} /></span>
                                                    <h4>shahroj khan</h4>
                                                </div></th>
                                                <td>Left sidebar adjusments	</td>
                                                <td>Aug 10, 2023</td>
                                                <td><span><AiFillGithub size={26} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"> <div className="d-flex">
                                                    <span className="pr-2"><BiSolidUserCircle size={30} /></span>
                                                    <h4>shahroj khan</h4>
                                                </div></th>
                                                <td>Main structure markup	</td>
                                                <td>Aug 10, 2023</td>
                                                <td><span><AiFillGithub size={26} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"> <div className="d-flex">
                                                    <span className="pr-2"><BiSolidUserCircle size={30} /></span>
                                                    <h4>shahroj khan</h4>
                                                </div></th>
                                                <td>Initial commit	</td>
                                                <td>Aug 10, 2023</td>
                                                <td><span><AiFillGithub size={26} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"> <div className="d-flex">
                                                    <span className="pr-2"><BiSolidUserCircle size={30} /></span>
                                                    <h4>shahroj khan</h4>
                                                </div></th>
                                                <td>Main structure markup</td>
                                                <td>Aug 10, 2023</td>
                                                <td><span><AiFillGithub size={26} /></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-4 mb-3">
                        <div className="col-lg-4 m-md-0 mb-3">
                            <div className="profile_box position-relative bg-white d-flex flex-column justify-content-center align-items-center p-4">
                                <div className="profile_box_header_iocn pb-2 position-relative">
                                    <span>
                                        <BiSolidUserCircle size={120} />
                                    </span>
                                    <div className="position-absolute bottom-0 end-0 ml-5 mb-2 bg-dark p-2 rounded-circle d-flex justify-content-center align-items-center">
                                        <span><FaUserEdit size={22} color="#fff" /></span>
                                    </div>
                                </div>
                                <div className="profile_box_header_content pb-2">
                                    <h3 className="mb-0">Shahroj khan</h3>
                                    <p className="m-0">ui/ux developer</p>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                    <div className="col-md-4">
                                        <div className="content_div">
                                            <h4 className="m-0">ISSUES</h4>
                                            <h1 className="fs-2 mb-0"><b>100</b></h1>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="content_div">
                                            <h4 className="m-0">COMMITS
                                            </h4>
                                            <h1 className="fs-2 mb-0"><b>100</b></h1>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="content_div">
                                            <h4 className="m-0">FOLLOWERS
                                            </h4>
                                            <h1 className="fs-2 mb-0"><b>100</b></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="bg-white p-md-4 p-4">
                                <div className="top_content">
                                    <h3 className="m-0">About Me</h3>
                                    <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <hr className="mb-0 mt-1"></hr>
                                </div>
                                <div className="bottom_content mt-3">
                                    <div className="row mb-4">
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Ocupation</h3>
                                                    <p className="m-0">Developer and designer
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Birthday</h3>
                                                    <p className="m-0">16 September 1989
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Mobile</h3>
                                                    <p className="m-0">(999) 999-9999
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Location</h3>
                                                    <p className="m-0">Montreal, Canada</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Email</h3>
                                                    <p className="m-0">Khan@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="right_div">
                                                    <h3 className="mb-0">Website</h3>
                                                    <p className="m-0">www.website.com

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Details;