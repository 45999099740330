const initialState = {
    constests: [],
    constest: {},
    loading: false,
    createStatus: false,
    deleteStatus: false,
    createWinnerStatus: false,
    updateStatus: false,
    errors: {},
    createWinner : {}
}

const constestMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CONTESTS_PENDING":
        case "DELETE_CONTEST_PENDING":
        case "CREATE_CONTEST_PENDING":
        case "GET_CONTEST_BY_ID_PENDING":
        case "CREATE_CONTEST_WINNER_PENDING":
        case "DELETE_CONTEST_WINNER_PENDING":
        case "UPDATE_CONTEST_WINNER_PENDING":
        case "UPDATE_CONTEST_PENDING":
            return {
                ...state,
                loading: true,
                createStatus: false,
                deleteStatus: false,
                createWinnerStatus: false,
                updateStatus: false,
            };

        case "CREATE_CONTEST_FULFILLED":
            return {
                ...state,
                loading: false,
                createStatus: true,

            };
        case "DELETE_CONTEST_FULFILLED":
        case "DELETE_CONTEST_WINNER_FULFILLED":
            return {
                ...state,
                loading: false,
                deleteStatus: true,

            };

        case "GET_CONTESTS_FULFILLED":

            return {
                ...state,
                loading: false,
                constests: action.payload
            };
        case "GET_CONTEST_BY_ID_FULFILLED":

            return {
                ...state,
                loading: false,
                constest: action.payload
            };
        case "CREATE_CONTEST_WINNER_FULFILLED":
            return {
                ...state,
                loading: false,
                createWinnerStatus: true,
                createWinner : action.payload
            };
        case "UPDATE_CONTEST_WINNER_FULFILLED":
        case "UPDATE_CONTEST_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,

            };
        case "GET_CONTESTS_REJECTED":
        case "CREATE_CONTEST_REJECTED":
        case "DELETE_CONTEST_REJECTED":
        case "GET_CONTEST_BY_ID_PENDING":
        case "CREATE_CONTEST_WINNER_REJECTED":
        case "DELETE_CONTEST_WINNER_REJECTED":
        case "UPDATE_CONTEST_WINNER_REJECTED":
        case "UPDATE_CONTEST_REJECTED":
            return {
                ...state,
                loading: false,
                errors: action?.payload?.response?.data,
                createStatus: false,
                deleteStatus: false,
                createWinnerStatus: false,
                updateStatus: false,
            };

        default:
            return state;
    }
}

export default constestMasterReducer;