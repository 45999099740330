import React, { useEffect,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import { 
    getPlayerView,
    deletePlayer
 } from "../../../redux/actions/PlayerMasterAction";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const [modal,setModal] = useState(false);
    const [url,setUrl] = useState({});
    const dispatch = useDispatch();
    const { playerMasterReducer: { player,deleteStatus }, AuthReducer: { auth } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getPlayerView(auth?.token))
    }, [auth])
    const handleDelete= (data) => {
        dispatch(deletePlayer(auth?.token,data?.id))
    }
    const handleModal = (row) => {
        setUrl(row)
        setModal(!modal);
        
    };
    useEffect(() => {
        if(deleteStatus) {
            toast.success("Deleted Successfully!!");
            dispatch(getPlayerView(auth?.token))
        }
    },[deleteStatus])
    return (
        <>
            <SimpleHeader name="Player Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Players</h3></Col>
                                    <Col xs="4" className="text-right">
                                        <a href="/admin/create-player" className="btn btn-neutral" color="default" size="sm" type="button">
                                           New Player
                                        </a >
                                    </Col>
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                data={player}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "",
                                        text: "Player",
                                        formatter: (cell, row) => {
                                            return <img alt={`${row.fullName}`} class="avatar rounded-circle mr-3" src={`${row.image_path}`} onClick={()=>handleModal(row)}/>;
                                        },
                                    },
                                    {
                                        dataField: "country.name",
                                        text: "Country",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "player_type.name",
                                        text: "Player Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "fullName",
                                        text: "Player Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "dateOfBirth",
                                        text: "DOB",
                                        sort: true,
                                    },
                                    {
                                        dataField: "gender",
                                        text: "Gender",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return row.gender=='m' ? 'Male' : 'Female'; 
                                        },
                                    },
                                    {
                                        dataField: "battingstyle",
                                        text: "Batting Style",
                                        sort: true,
                                    },
                                    {
                                        dataField: "bowlingstyle",
                                        text: "Bowling Style",
                                        sort: true,
                                    },
                                    {
                                        dataField: "fantacyCredit",
                                        text: "Fantacy Point",
                                        sort: true,
                                    },
                                    

                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {
                                            
                                            return (
                                                <>
                                                    <a href={`/admin/edit-player/${row?.id}`} className="btn btn-neutral" type="button" >
                                                        Edit
                                                    </a>
                                                    <Button color="danger" outline type="button" onClick={()=>handleDelete(row)}>
                                                        Delete
                                                    </Button>
                                                </>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   <img alt={`${url.name}`} class="w-100" src={`${url.image_path}`}/>;

                </Modal>


            </Container>
        </>
    )
}

export default Index;

