import { configureStore } from "@reduxjs/toolkit"
import AuthReducer from "./reducers/AuthReducer"
import PermissionReducer from "./reducers/PermissionReducer"
import indexReducer from "./reducers/indexReducer";
import sportMasterReducer from "./reducers/SportMasterReducer";
import constestMasterReducer from "./reducers/ContestMasterReducer";
import fixtureMasterReducer from "./reducers/FixtureMasterReducer";
import playerTypeMasterReducer from "./reducers/PlayerTypeMasterReducer";
import groundMasterReducer from "./reducers/GroundMasterReducer";
import scoreMasterReducer from "./reducers/ScoreMasterReducer";
import teamMasterReducer from "./reducers/TeamMasterReducer";
import playerMasterReducer from "./reducers/PlayerMasterReducer";
import playerCreditDebitAmountReducer from "./reducers/PlayerCreditDebitamountReducer";
import userReducer from "./reducers/UserReducer";
import couponReducer from "./reducers/CouponReducer";
import transactionReducer from "./reducers/TransactionReducer";
import ReferalSystemReducer from "./reducers/ReferalSystemReducer";
import WithdrawalReducer from "./reducers/WithdrawalReducer";
import thunk from "redux-thunk"
const Store = configureStore({
    reducer: {
        AuthReducer,
        PermissionReducer,
        indexReducer,
        sportMasterReducer,
        constestMasterReducer,
        fixtureMasterReducer,
        playerTypeMasterReducer,
        groundMasterReducer,
        scoreMasterReducer,
        teamMasterReducer,
        playerMasterReducer,
        playerCreditDebitAmountReducer,
        userReducer,
        couponReducer,
        transactionReducer,
        ReferalSystemReducer,
        WithdrawalReducer
    },
    middleware: [thunk]
})

export default Store