import axios from "axios";

export const getGroundView = (token) => async(dispatch) => {
    try{
        
        dispatch({type:"GET_GROUND_PENDING"});
        const response = await axios('/api/admin/v1/ground-lists',{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        
        dispatch({type:"GET_GROUND_FULFILLED",payload:response.data?.data})
    }catch(error) {
        console.log(error)
        dispatch({type:"GET_GROUND_REJECTED",payload:error});
    }
}