import axios from "axios";

export const getCouponView = (token) => async (dispatch) => {
    try {

        dispatch({ type: "GET_COUPONS_PENDING" });
        const response = await axios('/api/admin/v1/coupons', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        dispatch({ type: "GET_COUPONS_FULFILLED", payload: response.data?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: "GET_COUPONS_REJECTED", payload: error });
    }
}



export const createCoupon = (token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"CREATE_COUPON_PENDING"})
        const response = await axios.post(`/api/admin/v1/coupon-create`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "CREATE_COUPON_FULFILLED", payload: response.data?.data })
    }catch(error) {
     
       dispatch({type:"CREATE_COUPON_REJECTED",payload:error})
    }
}

export const updateCoupon = (id,token,data) => async(dispatch) =>  {
    try{
        dispatch({type:"UPDATE_COUPON_PENDING"})
        const response = await axios.put(`/api/admin/v1/coupon-update/${id}`,data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "UPDATE_COUPON_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
       dispatch({type:"UPDATE_COUPON_REJECTED",payload:error})
    }
}

export const deleteCoupon = (token, id) => async (dispatch) => {
    try{
        dispatch({ type: "DELETE_COUPON_PENDING" });
        const response = await axios.delete(`/api/admin/v1/coupon-delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({ type: "DELETE_COUPON_FULFILLED", payload: response.data?.data })
    }catch(error) {
        console.log(error)
        dispatch({ type: "DELETE_COUPON_REJECTED", payload: error });
    }
}

export const getCouponByIDs = (token,id) => async(dispatch) => {
    try{
        dispatch({type:"GET_COUPON_BY_ID_PENDING"});
        const response = await axios(`/api/admin/v1/coupon/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        dispatch({ type: "GET_COUPON_BY_ID_FULFILLED", payload: response.data?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: "GET_COUPON_BY_ID_PENDING", payload: error });
    }
}

