const initialState = {
    playerCreditDebitAmount: [],
    loading: false,
    createStatus: false,
    deleteStatus: false,
    updateStatus: false,
    playerByID: {},
    errors: {}
}

const playerCreditDebitAmountReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PLAYER_CREDIT_DEBIT_AMOUNT_PENDING":
        case "CREDIT_USER_WALLET_PENDING":
        case "DEBIT_USER_WALLET_PENDING":
        case "DELETE_PLAYER_PENDING":
        case "CREATE_PLAYER_PENDING":
        case "UPDATE_PLAYER_PENDING":
        case "GET_PLAYER_BY_ID_PENDING":
            return {
                ...state,
                loading: true,
                updateStatus: false
            };

        case "CREATE_PLAYER_FULFILLED":
            return {
                ...state,
                loading: false,
                createStatus: true,
            };
        case "UPDATE_PLAYER_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,

            };

        case "DELETE_PLAYER_FULFILLED":
            return {
                ...state,
                loading: false,
                deleteStatus: true,

            };

        case "GET_PLAYER_CREDIT_DEBIT_AMOUNT_FULFILLED":
            return {
                ...state,
                loading: false,
                playerCreditDebitAmount: action.payload
            };

        case "CREDIT_USER_WALLET_FULLFILLED":
            return {
                ...state,
                loading: false,
                playerCreditDebitAmount: action.payload
            };

            case "DEBIT_USER_WALLET_FULLFILLED":
                return {
                    ...state,
                    loading: false,
                    playerCreditDebitAmount: action.payload
                };
       

        case "GET_PLAYER_BY_ID_FULFILLED":
            return {
                ...state,
                loading: false,
                playerByID: action.payload
            };

        case "GET_PLAYER_CREDIT_DEBIT_AMOUNT_REJECTED":
        case "CREATE_PLAYER_REJECTED":
        case "CREDIT_USER_WALLET_REJECTED":
        case "DEBIT_USER_WALLET_REJECTED":
        case "DELETE_PLAYER_REJECTED":
        case "UPDATE_PLAYER_REJECTED":
        case "GET_PLAYER_BY_ID_REJECTED":
            return {
                ...state,
                loading: false,
                errors: action.payload,
                createStatus: false,
                deleteStatus: false,
                updateStatus: false
            };

        default:
            return state;
    }
}

export default playerCreditDebitAmountReducer;