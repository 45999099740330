import React, { useEffect,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import { 
    getUsersView,
    updateUserStatus
 } from "../../../redux/actions/UserActions";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
import NoImage from "../../../assets/img/download.png"
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const [modal,setModal] = useState(false);
    const [url,setUrl] = useState({});
    const dispatch = useDispatch();
    const { userReducer: { users }, AuthReducer: { auth } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getUsersView(auth?.token))
    }, [auth])
    
    const handleModal = (row) => {
        setUrl(row)
        setModal(!modal);
        
    };

    const updateStatus = (id, status) => {
        var data = {
            id : id,
            status : status
        };
        dispatch(updateUserStatus(auth?.token, data))
        alert("Status updated successfully!!");
        dispatch(getUsersView(auth?.token))
    }
    return (
        <>
            <SimpleHeader name="User Lists" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Users</h3></Col>
                                    {/* <Col xs="4" className="text-right">
                                        <a href="/admin/create-player" className="btn btn-neutral" color="default" size="sm" type="button">
                                           New Player
                                        </a >
                                    </Col> */}
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                data={users}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "username",
                                        text: "User Name",
                                        sort: true,
                                    },
                                    // {
                                    //     dataField: "email",
                                    //     text: "Email",
                                    //     sort: true,
                                        
                                    // },
                                    {
                                        dataField: "mobile",
                                        text: "Mobile",
                                        sort: true,
                                    },
                                    {
                                        dataField: "state",
                                        text: "State",
                                        sort: true,
                                    },
                                    {
                                        dataField: "city",
                                        text: "City",
                                        sort: true,
                                    },
                                    {
                                        dataField: "gender",
                                        text: "Gender",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return row.gender=='m' ? 'Male' : 'Female'; 
                                        },
                                    },
                                    {
                                        dataField: "aadhaarNumber",
                                        text: "Adhaar No",
                                        sort: true,
                                    },
                                    {
                                        dataField: "dob",
                                        text: "DOB",
                                        sort: true,
                                    },
                                    {
                                        dataField: "bankName",
                                        text: "Bank Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "accountNo",
                                        text: "Account Number",
                                        sort: true,
                                    },
                                    {
                                        dataField: "branchName",
                                        text: "Branch Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "ifscCode",
                                        text: "IFSC Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "",
                                        text: "KYC Document",
                                        formatter: (cell, row) => {
                                            return <img alt={`${row.kyc_document}`} class="avatar rounded-circle mr-3" src={`${row.kyc_document == 'undefined' ? NoImage : NoImage}`} onClick={()=>handleModal(row)}/>;
                                        },
                                    },
                                    {
                                        dataField: "",
                                        text: "Profile",
                                        formatter: (cell, row) => {
                                            return <img alt={`${row.thumbnail}`} class="avatar rounded-circle mr-3" src={`${row.thumbnail == 'undefined' ? NoImage : NoImage}`} onClick={()=>handleModal(row)}/>;
                                        },
                                    },
                                    

                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {
                                            
                                            return (
                                                <>
                                                    <a href={`/admin/edit-user/${row?.id}`} className="btn btn-neutral" type="button" >
                                                        Edit
                                                    </a><br></br><br></br>
                                                    <select onChange={(e) => updateStatus(row?.id, e.target.value)} className="form-control status_dropdown" name="" id="">
                                                        <option selected={row?.status==1 ? true : false} value="1">Active</option>
                                                        <option selected={row?.status==0 ? true : false} value="0">InActive</option>
                                                    </select>
                                                </>

                                            );
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   <img alt={`${url.name}`} class="w-100" src={`${url.image_path == 'undefined' ? NoImage : url.image_path }`}/>;

                </Modal>


            </Container>
        </>
    )
}

export default Index;

