const initialState = {
    ground:[],
    loading:false,
    errors:{}
}

const groundMasterReducer = (state=initialState,action) => {
    switch(action.type) {
        case "GET_GROUND_PENDING":
            return {...state,loading:true};
        
        case "GET_GROUND_FULFILLED":
            
            return {
                ...state,
                loading:false,
                ground:action.payload
            };

        case "GET_GROUND_REJECTED":
            return {
                ...state,
                loading:false,
                errors:action.payload
            };

        default:
            return state;
    }
}

export default groundMasterReducer;