const initialState = {
    users: [],
    withdrwals:[],
    loading: false,
    errors: {},
    createStatus: false,
    deleteStatus: false,
    updateStatus: false,
    userByID : {},
    checkUser : []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USERS_PENDING":
        case "GET_USERS_WITHDRWAL_PENDING":
            return { ...state, loading: true };
        case "GET_USER_BY_ID_PENDING":
            return {
                ...state,
                loading: true,
                updateStatus: false
            };
        case "UPDATE_PLAYER_PENDING":

        case "GET_USERS_FULFILLED":
            return {
                ...state,
                loading: false,
                users: action.payload
            };
        case "UPDATE_USER_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,
                checkUser : action.payload
            };
        case "UPDATE_PLAYER_FULFILLED":
            return {
                ...state,
                loading: false,
                updateStatus: true,
            };
        case "GET_USER_BY_ID_FULFILLED":
            return {
                    ...state,
                    loading: false,
                    userByID: action.payload
            };

        case "GET_USERS_WITHDRWA_FULFILLED":
            return {
                ...state,
                loading: false,
                withdrwals: action.payload
            };

        case "GET_USERS_REJECTED":
        case "UPDATE_PLAYER_REJECTED":
        case "GET_USERS_WITHDRWA_REJECTED":
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
            case "GET_USER_BY_ID_REJECTED":
                return {
                    ...state,
                    loading: false,
                    errors: action.payload,
                    createStatus: false,
                    deleteStatus: false,
                    updateStatus: false
                };

        default:
            return state;
    }
}

export default userReducer;