import React, { useEffect,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from 'react-redux'
import { 
    getPlayerCreditDebitAmountView,
    deletePlayer,
    getUserWallet,
    creditUserWallet,
    debitUserWallet
 } from "../../../redux/actions/PlayerCreditDebitAmountAction";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const CreditDebitAmount = () => {
    const [modal,setModal] = useState(false);
    const [modal2,setModal2] = useState(false);
    const [url,setUrl] = useState({});
    const [mobile_number, setMobileNumber] = useState("");
    const [credit_amount, setCreditamount] = useState("");
    const [credit_to, setCreditTo] = useState("");
    const [debit_amount, setDebitamount] = useState("");
    const [debit_to, setDebitTo] = useState("");
    const [transaction_type, setTransactionType] = useState("");
    const [transaction_type2, setTransactionType2] = useState("");
    const [remarks, setRemarks] = useState("");
    const [remarks2, setRemarks2] = useState("");
    const [userId, setUserId] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const { playerCreditDebitAmountReducer: { playerCreditDebitAmount,deleteStatus }, AuthReducer: { auth } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getPlayerCreditDebitAmountView(auth?.token));
    }, [auth])
    const handleDelete= (data) => {
        dispatch(deletePlayer(auth?.token,data?.id))
    }
    const handleModal = (row) => {
        setUrl(row)
        setModal(!modal);
        
    };
    useEffect(() => {
        if(deleteStatus) {
            toast.success("Deleted Successfully!!");
            dispatch(getPlayerCreditDebitAmountView(auth?.token))
        }
    },[deleteStatus])

    function filterUserWallet(){
        var data = {
            mobile_number : mobile_number
        }
        dispatch(getUserWallet(auth?.token,data))
    }

    const CreditToWallet = () => {
        var userId = playerCreditDebitAmount?.[0]?.id;
        if(credit_amount=='' || credit_to=='' || transaction_type=='' || remarks==''){
            setError("Please fill all the fields to add credit amount!!");
            return false;
        }else{
            var data = {
                userId : userId,
                transaction_type : "credit",
                transaction_detail : transaction_type,
                transaction_amount : credit_amount,
                credit_to : credit_to
            };
            dispatch(creditUserWallet(auth?.token, data));
            setModal(false);
            var data = {
                mobile_number : mobile_number
            }
            setTimeout(() => {
                dispatch(getUserWallet(auth?.token,data))
            }, 200);
            
        }
    }

    const DebitToWallet = () => {
        var userId = playerCreditDebitAmount?.[0]?.id;
        if(debit_amount=='' || debit_amount=='' || transaction_type2=='' || remarks2==''){
            setError("Please fill all the fields to add credit amount!!");
            return false;
        }else{
            var data = {
                userId : userId,
                transaction_type : "debit",
                transaction_detail : transaction_type2,
                transaction_amount : debit_amount,
                debit_to : debit_to
            };
            dispatch(debitUserWallet(auth?.token, data))
            setModal2(false);
            var data = {
                mobile_number : mobile_number
            }
            setTimeout(() => {
                dispatch(getUserWallet(auth?.token,data))
            }, 200);
        }
    }

    return (
        <>
            <SimpleHeader name="Credit/Debit Amount" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Credit/Debit Amount</h3></Col>
                                    
                                    <Col xs="4" className="text-right">
                                        {/* <a href="/admin/create-player" className="btn btn-neutral" color="default" size="sm" type="button">
                                           New Player
                                        </a > */}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Col xs="4">
                            <input onChange={(e) => setMobileNumber(e.target.value)} placeholder="Username or Mobile Number" className="form-control" type="text"></input><br />
                            <button onClick={() => filterUserWallet()} class="btn btn-primary">Filter</button>
                            </Col>
                            <ToolkitProvider
                                data={playerCreditDebitAmount}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "name",
                                        text: "Player Username",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "mobile",
                                        text: "Mobile Number",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "deposit_wallet",
                                        text: "Deposit Wallet",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "winning_wallet",
                                        text: "Winning Wallet (Rs)",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "on_hold_withdraw_amount",
                                        text: "On Hold Withdraw Amount (Rs)",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "bonus_cash",
                                        text: "Bonus Cash",
                                        sort: true,
                                    },
                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {
                                            
                                            return (
                                                <>
                                                    <a onClick={() => setModal(true)} className="btn btn-success" type="button" >
                                                        Credit
                                                    </a>
                                                    <a onClick={() => setModal2(true)}  className="btn btn-danger" type="button" >
                                                        Debit
                                                    </a>
                                                </>

                                            );
                                        },
                                    },
                                   
                                    

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            {/* <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label> */}
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   
                    <div style={{"padding":"40px"}} class="row">
                        <p style={{"color":"red"}}>
                            {error}
                        </p>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="text-danger ng-binding" ng-bind-html="error_message"></span>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Add Credit Amount (Rs) </label>
                                        <input onChange={(e) => setCreditamount(e.target.value)} type="text" class="form-control" name="amount"/>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Credit To </label>
                                        <select onChange={(e) => setCreditTo(e.target.value)} id="request_status" class="form-control" name="credit_to">
                                            <option value="" disabled="" selected="selected">Choose</option>
                                            <option value="Deposit Wallet">Deposit Wallet</option>
                                            <option value="Winning Wallet">Winning Wallet</option>
                                            <option value="On hold Withdraw Amount">On hold Withdraw Amount</option>
                                            <option value="Bonus Cash">Bonus Cash</option>
                                        </select>
                                        
                                    </div>
                                </div>  
                            </div>



                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Transaction Type</label>
                                        <select onChange={(e) => setTransactionType(e.target.value)} id="type" class="form-control" name="type">
                                            <option value="" disabled="" selected="selected">Choose</option>
                                            <option value="demo amount">Demo Amount</option>
                                            <option value="phonepay_topup">Phonepay Topup</option>
                                            <option value="Join contest">Join contest</option>
                                            <option value="Topup">Topup</option>
                                        </select> 
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Remarks </label>
                                        <textarea onChange={(e) => setRemarks(e.target.value)} class="form-control" name="remark"></textarea>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style={{"margin-bottom":"15px"}}> 
                    
                    <button onClick={() => setModal(false)} class="btn btn-default"><b>Cancel</b></button>
                    <button onClick={() => CreditToWallet()} class="btn btn-success"><b>Add</b></button>
                    
                    </div>
                   
                

                </Modal>

                <Modal
                    isOpen={modal2}
                    toggle={() => setModal2(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   
                    <div style={{"padding":"40px"}} class="row">
                        <p style={{"color":"red"}}>
                            {error}
                        </p>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="text-danger ng-binding" ng-bind-html="error_message"></span>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Add Debit Amount (Rs) </label>
                                        <input onChange={(e) => setDebitamount(e.target.value)} type="text" class="form-control" name="amount"/>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Debit To </label>
                                        <select onChange={(e) => setDebitTo(e.target.value)} id="request_status" class="form-control" name="credit_to">
                                            <option value="" disabled="" selected="selected">Choose</option>
                                            <option value="Deposit Wallet">Deposit Wallet</option>
                                            <option value="Winning Wallet">Winning Wallet</option>
                                            <option value="On hold Withdraw Amount">On hold Withdraw Amount</option>
                                            <option value="Bonus Cash">Bonus Cash</option>
                                        </select>
                                        
                                    </div>
                                </div>  
                            </div>



                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Transaction Type</label>
                                        <select onChange={(e) => setTransactionType2(e.target.value)} id="type" class="form-control" name="type">
                                            <option value="" disabled="" selected="selected">Choose</option>
                                            <option value="demo amount">Demo Amount</option>
                                            <option value="phonepay_topup">Phonepay Topup</option>
                                            <option value="Join contest">Join contest</option>
                                            <option value="Topup">Topup</option>
                                        </select> 
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Remarks </label>
                                        <textarea onChange={(e) => setRemarks2(e.target.value)} class="form-control" name="remark"></textarea>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style={{"margin-bottom":"15px"}}> 
                    
                    <button onClick={() => setModal2(false)} class="btn btn-default"><b>Cancel</b></button>
                    <button onClick={() => DebitToWallet()} class="btn btn-success"><b>Minus</b></button>
                    
                    </div>
                   
                

                </Modal>


            </Container>
        </>
    )
}

export default CreditDebitAmount;

