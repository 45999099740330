import React, { useEffect,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import store from "../../../redux/Store";
import { useDispatch, useSelector } from 'react-redux'
import { 
    getReferalSystem,
    upRefSystem,
    deletePlayer
 } from "../../../redux/actions/ReferalSystemAction";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { toast } from "react-toastify";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

const Index = () => {
    const [modal,setModal] = useState(false);
    const [url,setUrl] = useState({});
    const [invited_person_amount, setInvitedPersonamount] = useState("");
    const [joined_person_amount, setJoinedPersonamount] = useState("");
    const [referral_bonus_type, setReferralBonusType] = useState("");
    const dispatch = useDispatch();
    const { ReferalSystemReducer: { referal_system,deleteStatus }, AuthReducer: { auth } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getReferalSystem(auth?.token))
        setTimeout(() => {
            var refSystem = store.getState().ReferalSystemReducer.referal_system;
            setInvitedPersonamount(refSystem?.[0]?.invited_person_amount);
            setJoinedPersonamount(refSystem?.[0]?.joined_person_amount);
            setReferralBonusType(refSystem?.[0]?.referral_bonus_type);
        }, 1000);
    }, [auth])
    const handleDelete= (data) => {
        dispatch(deletePlayer(auth?.token,data?.id))
    }
    const handleModal = (row) => {
        setUrl(row)
        setModal(!modal);
        
    };
    useEffect(() => {
        if(deleteStatus) {
            toast.success("Deleted Successfully!!");
            dispatch(getReferalSystem(auth?.token))
        }
    },[deleteStatus])

    const updateReferalSystem = () => {
        if(invited_person_amount=='' || joined_person_amount=='' || referral_bonus_type==''){
            alert("Please fill all the fields to update referal system!!");
            return false;
        }else{
            var data = {
                invited_person_amount : invited_person_amount,
                joined_person_amount : joined_person_amount,
                referral_bonus_type : referral_bonus_type
            };
            dispatch(upRefSystem(auth?.token, data));
            setModal(false);
            setTimeout(() => {
                dispatch(getReferalSystem(auth?.token,data))
            }, 200);
            
        }
    }

    return (
        <>
            <SimpleHeader name="Referal System" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="8"> <h3 className="mb-0">Referal system</h3></Col>
                                    <Col xs="4" className="text-right">
                                        {/* <a href="/admin/create-player" className="btn btn-neutral" color="default" size="sm" type="button">
                                           New Player
                                        </a > */}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                data={referal_system}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: 'sl.no',
                                        text: 'Sl no.',
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return rowIndex + 1;
                                        },
                                        sort: true,
                                    },
                                    
                                    {
                                        dataField: "invited_person_amount",
                                        text: "Invited Person Amount",
                                        sort: true,
                                        
                                    },
                                    {
                                        dataField: "joined_person_amount",
                                        text: "Joined person amount",
                                        sort: true,
                                    },

                                    {
                                        dataField: "referral_bonus_type",
                                        text: "Bonus type",
                                        sort: true,
                                    },
                            
                                    

                                    {
                                        dataField: "",
                                        text: "Status",
                                        formatter: (cellContent, row) => {
                                           if(row.status==1){
                                            return (
                                                <>
                                                    <a className="btn btn-success" type="button" >
                                                        Active
                                                    </a>
                                                   
                                                </>

                                            );
                                           }else{
                                            return (
                                                <>
                                                    <a className="btn btn-success" type="button" >
                                                        In-Active
                                                    </a>
                                                   
                                                </>

                                            );
                                           }
                                            
                                            
                                        },
                                    },

                                    {
                                        dataField: "",
                                        text: "Action",
                                        formatter: (cellContent, row) => {
                                            return (
                                                <>
                                                    <a onClick={() => setModal(true)} className="btn btn-primary" type="button" >
                                                        Edit
                                                    </a>
                                                   
                                                </>

                                            );
                                            
                                            
                                        },
                                    },

                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
                <Modal
                    isOpen={modal}
                    toggle={() => setModal(false)}
                    className="modal-dialog-centered modal-secondary"
                >
                   
                    <div style={{"padding":"40px"}} class="row">
                        {/* <p style={{"color":"red"}}>
                            {error}
                        </p> */}
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="text-danger ng-binding" ng-bind-html="error_message"></span>
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Invited person amount </label>
                                        <input onChange={(e) => setInvitedPersonamount(e.target.value)} type="text" value={invited_person_amount} class="form-control" name="amount"/>
                                        
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Joined person amount </label>
                                        <input onChange={(e) => setJoinedPersonamount(e.target.value)} type="text" value={joined_person_amount} class="form-control" name="amount"/>
                                        
                                    </div>
                                </div>   
                            </div>



                            <div class="row"> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Bonus Type</label>
                                        <select onChange={(e) => setReferralBonusType(e.target.value)} id="type" class="form-control" name="type">
                                            <option value="" disabled="" selected="selected">Choose</option>
                                            <option selected={(referral_bonus_type=="percentage") ? true : false} value="percentage">Percentage</option>
                                            <option selected={(referral_bonus_type=="flat") ? true : false}  value="flat">Flat</option>
                                            
                                        </select> 
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-12" style={{"margin-bottom":"15px"}}> 
                    
                    <button onClick={() => setModal(false)} class="btn btn-default"><b>Cancel</b></button>
                    <button onClick={() => updateReferalSystem()} class="btn btn-success"><b>Update</b></button>
                    
                    </div>
                   
                

                </Modal>


            </Container>
        </>
    )
}

export default Index;

